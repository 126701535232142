export const TRANSLATIONS_EN = {
  CodeMismatchException: 'Invalid code',
  CommunicationProblemModalDescription:
    'Our servers are not responding. Please try again.<br />If the problem persists, please contact us at info@eb.com',
  CommunicationProblemModalTitle: 'Oops! We had a technical problem.',
  ExpiredCodeException: 'Invalid code',
  IAlreadyHaveAnAccount: 'I already have an account',
  LimitExceededException: 'Attempt limit exceeded, please try after some time.',
  UserNotConfirmedException:
    'Your account is not verified. Please enter the verification code sent to your email.',
  accountVerifiedCanLoginNow: 'Your account has been verified, you can login now',
  account_settings: 'Account Settings',
  achievmentsSection: {
    feedback: 'You’ve showed some extraordinary skills!',
    greatJob: 'Great job!',
    in: 'In',
    weeklyAchievement: 'Achievment of the week',
  },
  active: 'Active',
  activities: 'Activities',
  addClass: 'Add Class',
  addNewClass: 'Add New Class',
  advanceToTheNextLevel: 'advance to the next level.',
  advanced: 'Advanced',
  again: 'again',
  all: 'All',
  allDone: 'All done!',
  allLevels: 'All Levels',
  allowIndividualAdvancement: 'Allow Level Advancement',
  almostFinish: 'Almost finished!',
  almostHalfWayThere: "You're almost half-way there!",
  almostMadeIt: 'Almost made it',
  almostThere: "You're almost there...",
  almost_made_it: 'Almost made it',
  amazingJob: 'Amazing Job!',
  amazing_job: 'Amazing job!',
  anErrorOccurred: 'An error has occurred',
  pageNotFound: 'Page Not Found',
  couldNotLocate: 'We couldn’t locate the page you’re looking for.',
  anUnauthorizedRequestErrorOccurred: 'An unauthorized request error occurred.',
  andBeginYour: 'and begin your English-learning journey.',
  andInviteYourStudents: 'and invite your students',
  andThenInvite: 'and then invite your students to join the class',
  andYouCanImproveYouScores: 'and you can improve your scores.',
  and_got_all_the_stars: 'and got all the stars',
  answerNotAccepted: 'Answer not accepted',
  answeredIncorrectly: 'answered incorrectly.',
  appName: 'Hello Britannica',
  appTitle: 'Britannica',
  app_banner: 'App Banner',
  ar: 'عربيه',
  areYouReadyForTheQuiz: "Do you think your're ready for the game?",
  areYouSure: 'Are you sure?',
  asSoonAsTheyStart: 'Once your student changes their temporary password, they will become active',
  assessment: 'Assessment',
  assessments: 'Assessments',
  assignments: 'Assignments',
  atLeastOneNumber: 'Contains at least 1 number',
  atLeastOneSpecial: 'Contains at least 1 special character',
  atLeastOneUppercase: 'Contains at least 1 uppercase letter',
  averageStudentLevel: 'Average Student Level',
  avgAttempts: 'Avg. Attempts',
  avgClassScores: 'Avg. Class Scores',
  avgFailureRate: 'Avg Failure Rate',
  avgGameScores: 'Avg. Game Scores',
  avgLevel: 'Average level',
  avgLevel_mobile: 'Avg. level',
  avgPassRate: 'Avg Pass Rate',
  avgProgress: 'Average progress',
  avgProgress_mobile: 'Avg. progress',
  avgQuizzes: 'Avg. quizzes completed per student',
  avgScore: 'Average score',
  avgScore_mobile: 'Avg. score',
  avgTimeSpent: 'Avg. Time Spent',
  back: 'Back',
  backHome: 'Back Home',
  backToHomePage: 'Back to home',
  backToTopics: 'Back to topics',
  back_home: 'Back Home',
  badgesSection: {
    link: 'View all',
    title: 'My Recent Badges',
  },
  britannicaEducationWith: 'Britannica Education with',
  byClickingOn:
    'By clicking on the student’s name, you’ll see how they are performing in each skill and how often they use the app . You can also decide which interface language they should use.',
  canDo: 'Can Do',
  cancel: 'Cancel',
  cancelAnyway: 'Cancel anyway',
  changeLevel: 'Change Level',
  change_password: 'Change Password',
  characters: 'Characters',
  checkInSpamMessage: 'Didn’t receive it? Try checking your <0>spam</0> folder.',
  checkInSpamMessageTeacher: '',
  choose: 'Choose',
  chooseAClass: 'Choose a class',
  chooseAvatar: 'Choose Avatar',
  chooseDate: 'Choose Date',
  chooseGrade: 'Choose grade',
  chooseTheCorrectAnswer: 'Choose the correct answer to fill in the sentence:',
  chooseYourFavoriteAvatar: 'Choose your favorite avatar and color',
  city: 'City or District',
  class: 'Class',
  classActivity: 'Class Activity',
  classAvg: 'Class Avg',
  classCreated: 'The class has been created successfully!',
  classCreatedDescription:
    'Your students have been sent an email with instructions on resetting their password. Once they’ve done that, they’ll begin their English learning journey.',
  className: 'Class Name',
  classReport: 'Class Report',
  classes: 'Classes',
  clickOnAStudent: 'Click on a student',
  clickToCopy: 'Click to copy your invitation',
  clickToTryAgain: 'Click to try again',
  clickToWatchAssessments: 'Click to watch student’s assessments!',
  coins: 'Coins',
  coinsInstructions: 'You can use them to get help during the games.',
  coinsWillBeLost: 'and the coins you won will be lost',
  comingSoon: 'Coming<br/>Soon',
  complete: 'Complete',
  completed: 'Completed',
  completedActivities: 'Total activities completed in this level',
  confirmPassword: 'Confirm Password',
  contactOurTeam: 'Contact our team at',
  contactOurTeamWithQuestions: 'Contact our team with questions and concerns.',
  contactUs: 'Contact Us',
  continue: 'Continue',
  continueCreating: 'Continue Creating',
  continueOn: 'Continue on',
  continueOnWeb: 'Continue on web',
  continueQuiz: 'Continue Game',
  continueToQuestions: 'Continue To Questions',
  continue_level_test: 'Continue Level Test',
  copy: 'Copy',
  copyInvitationLink: 'Copy Invitation Link',
  copyLink: 'Copy Link',
  copyLinkOnly: 'Copy link only',
  country: 'Country',
  create: 'Create',
  createClass: 'Create Class',
  createNewClass: 'Create Class',
  createYourClass: 'Create your class and invite your students',
  createYourFirstClass: 'Create your first class',
  createYourFirstClassDescription:
    'Set up your class, then upload a list of students you would like to enroll in that class. Then invite your students to join.',
  currentLevel: 'Current level',
  current_password: 'Current Password',
  customYourLearning: "Soon we'll be able to customize your learning experience!",
  dashboard: 'Dashboard',
  date: 'Date',
  dealLimitReachedMessageDescription:
    'Please contact your school administrator for guidance.<br/><br/>Error code: LR9D2F7H',
  dealLimitReachedMessageTitle: 'Your school’s user limit has been reached.',
  dearSchoolAdmin: 'Dear school administrator, you’ve been',
  dearStudent: 'Dear student, you’ve been invited by ',
  dearTeacher: 'Dear teacher, you’ve been invited to join',
  dearTeacherLevel: 'Dear Teacher,',
  dearTeacherPleaseJoin:
    'Dear teacher, please join {{schoolName}} so that you will be able to create your classes and start using Britannica’s English learning platform.',
  deleteClass: 'Delete class',
  dictionary: 'Dictionary',
  didYouMean: 'Did you mean',
  didntQuiteHearThat: "Didn't quite hear that.",
  discoverAWorld:
    'Discover a world of dynamic learning paths and robust tools to elevate your English language skills.',
  doingGreat: "You're doing great!",
  done: 'Done',
  dontKnow: "Don't Know",
  downloadAndView: 'Download and view',
  downloadPDF: 'Download PDF',
  editClass: 'Edit class',
  editClassInviteNewStudent: 'Add New Students',
  editClassInvitedStudentsText:
    'Once your students change their temporary password, they will become active.',
  editClassInvitedStudentsTitle: 'Students who were invited to this class',
  editClassStudentFullClassDescription:
    'Your class contains the maximum<br/>number of students allowed.',
  editClassStudentFullClassTitle: 'This class is already full.',
  editClassStudentNewStudentAddedDescription:
    'They have been sent an email with instructions on resetting their password. Once your student logs into the platform, they will receive a level test and be placed at their appropriate level and begin their learning journey.',
  editClassStudentNewStudentAddedTitle: 'has been invited!',
  editClassStudentStatus: 'Status',
  editClassStudentStatusActive: 'Active',
  editClassStudentStatusInactive: 'Inactive',
  email: 'Email',
  emailAlreadyExists: 'An account with the given email already exists',
  emailExample: 'example@gmail.com',
  emailIsValidReceiveVerificationCode:
    'If the email account is valid, you will receive a verification code to continue the password reset process.',
  emailVerification: 'Email Verification',
  empowerEnrichAndInspire: 'empower, enrich, and inspire us',
  en: 'English',
  endOfLevelAssessment: 'End of Level Assessment',
  english: 'English',
  englishLearningJourney: 'English-learning journey with Hello Britannica.',
  englishLearningJourneyMorfix: 'English-learning journey with Morfixschool 2.0.',
  englishSkillsAssessment: 'English Skills Assessment',
  englishSkillsAssessments: 'English Skills Assessments',
  englishSkillsProgress: 'English Skills Progress',
  enterCodeHere: 'Enter code here',
  enterNewPassword: 'Enter new password',
  enterVerificationCode: 'Enter Verification Code',
  enterVerificationCodeAndNewPassword: 'Enter verification code and new password',
  enterVerificationCodeHelpText: 'Enter the code you received from your referral partner.',
  es: 'Español',
  exampleEmail: 'example@gmail.com',
  exampleSentences: 'Example sentences',
  examples: 'examples',
  excellent: 'Excellent',
  exit: 'Exit',
  exploreEngaging:
    'Explore engaging lessons and fun activities that will make English learning enjoyable and effective.',
  extra: 'Extra',
  extraCoins: 'Extra Coins',
  failed_quiz_message_again: "You still didn't pass this game, so no extra coins, no stars.",
  failed_quiz_message_first: "You didn't pass this activity, so no stars.",
  failed_quiz_message_passed:
    "You didn't pass this time, but don't worry. Your previous score has been saved.",
  failureRate: 'Failure Rate',
  fewMoreSeconds: 'Few more seconds',
  fieldIsMandatory: 'This field is mandatory',
  filter: 'Filter',
  finalAssessment: 'Final Assessment',
  finalEnglishLevelTest: 'Final English level Test',
  finishActivity: 'Finish activity',
  finishGameText:
    'You’ve completed your Hello Britannica course.<br/> All of your activities will remain unlocked so you may go back and practice ',
  finishToLevelUp: 'Finish to level up!',
  finishUnitSuccessText:
    'You’ve completed the unit {{completedUnitName}}. You’re now ready to begin {{nextUnitName}}. Remember, you can always go back to this unit to review.',
  firstname: 'First Name',
  focus: 'Focus!',
  for: 'for',
  forgotPassword: 'Forgot password',
  foundation: 'Foundation',
  friday: 'friday',
  fridayShort: 'Fri',
  from: 'From',
  fullVersionAvilableOnlyOnDesktop: 'Full version available only on desktop',
  generalLevel: 'General Level',
  generateClassReport: 'Generate Class Report',
  generateReport: 'Generate Report',
  getApp: 'Get the app',
  getFullExperience: 'Get the full experience with the Hello Britannica app',
  getStarted: 'Get Started',
  gettingStarted: 'Getting Started',
  giveFeedback: 'Contact Us',
  goAheadAndShareThisLinkWithYourStudentsNow:
    'Go ahead and share this link with your students now. ',
  goAheadJoinMyClass: 'Hi, Go ahead join my class using this link',
  goBackToSkills: 'Go back to skills',
  goToLevelTest: 'Go to Level Test',
  goToSkill: 'Go to {{skill}}',
  go_back_btn: 'Go Back Button',
  good: 'Good',
  goodAfternoon: 'Good afternoon',
  goodEvening: 'Good evening',
  goodJob: 'Good job!',
  goodLuck: 'Good luck!',
  goodMorning: 'Good morning',
  goodNight: 'Good night',
  gotAllStars: 'and got all the stars',
  gotThis: 'Got this!',
  grade: 'Grade',
  grade_1: '1st',
  grade_10: '10th',
  grade_10th: '10th',
  grade_11: '11th',
  grade_11th: '11th',
  grade_12: '12th',
  grade_12th: '12th',
  grade_1st: '1st',
  grade_2: '2nd',
  grade_2nd: '2nd',
  grade_3: '3rd',
  grade_3rd: '3rd',
  grade_4: '4th',
  grade_4th: '4th',
  grade_5: '5th',
  grade_5th: '5th',
  grade_6: '6th',
  grade_6th: '6th',
  grade_7: '7th',
  grade_7th: '7th',
  grade_8: '8th',
  grade_8th: '8th',
  grade_9: '9th',
  grade_9th: '9th',
  grade_none: 'None',
  grade_university: 'University',
  grammar_tip: 'Grammar Tip',
  great: 'Great!',
  greatJob: 'Great job!',
  he: 'Hebrew',
  hello: 'Hello',
  helloBritannica: 'Hello Britannica',
  helloWorld: 'Hello, World!',
  help: 'Help',
  hi: 'Hi',
  hints: 'Hints',
  hints_options: {
    add_more_time: 'Add Time on Clock',
    hear_the_english_word: 'Hear the English word',
    remove_a_letter: 'Remove a letter',
    remove_one_answer: 'Remove one answer',
    reveal_a_pair: 'Reveal a pair',
    see_the_translation: 'See the translation',
    see_the_word: 'See the word',
    show_a_letter: 'Show a letter',
  },
  notFoundError: '404 Error',
  homepage: 'Home Page',
  howDidYouDo: 'How did you do?',
  howsYourEnglish: "How's Your English?",
  iAgree: 'I Agree',
  iAgreeTo: 'I agree to the',
  iAmReady: "I'm Ready!",
  iDidntUnderstand: 'I didn’t understand. Please try again.',
  iDoNotAgree: 'I Don’t Agree',
  id: 'ID',
  illDoItLater: 'I’ll do it later',
  improveYourLastActivities: 'Improve your last activities',
  tryToGetAllStars: 'This time, try to get all three stars!',
  improveYourRecentGames: 'Improve your recent games',
  improveYourRecentVocabularyQuizzes: 'Improve your recent vocabulary games',
  inAllGames: 'In All Games',
  in_order_to_proceed_ask_teacher:
    'In order to proceed to the next level, your teacher must allow you to do so.',
  in_order_to_start: 'In order to start your learning journey, we need to',
  inactive: 'inactive',
  incorrect: 'Incorrect!',
  incorrect_answer: 'incorrect.',
  initialLevelSetTest: 'Initial Level Set Test',
  initialLevelTest: 'Initial Level Test',
  instructionalMaterial: 'Instructional Material',
  interfaceLanguage: 'Interface language',
  interface_language: 'Interface Language',
  intermediate: 'Intermediate',
  invalidCode: 'Invalid code',
  invalidEmail: 'Invalid email',
  invalidField: 'Invalid field',
  invalidFirstname: 'Invalid firstname',
  invalidLastname: 'Invalid lastname',
  invalidPassword: 'Invalid password',
  invalidUsernameOrPassword: 'Invalid username or password',
  invitationLink: 'Invitation Link',
  invitationLinkWontChange: "Don't worry- invitation link won't change",
  inviteYourStudents: 'Invite Your Students',
  inviteYourTeachers: 'Invite your teachers to join Hello Britannica.',
  invitedToJoin: 'invited to join Hello Britannica.',
  invitedToJoinMorfix: 'invited to join Morfixschool 2.0.',
  isNotEnglish: 'For this activity, your writing needs to be in English. Please try again.',
  itLooksLikeYouReallyKnowTheMaterial:
    'It looks like you really know the material and are advancing nicely. Focus on completing all skills and you will continue to advance.',
  itLooksLikeYourLevel:
    'It looks like Level {{level}} is the right fit for now. Keep practicing, and you’ll keep improving!',
  itLooksLikeYourLevelMax:
    'Great job! It looks like Level {{level}} is the right fit for you. Let’s jump in.',
  itsTime: "It's time to choose whether you're ready for the game or not,",
  joinHere: 'Join here:',
  joinNow: 'Join Now',
  keepGoing: 'Keep going',
  keepPlaying: 'Keep playing!',
  keepPracticing: 'Keep practicing',
  keepUpTheGoodWork: 'Keep up the good work',
  know: 'Know',
  know_your_level: 'know your level of English.',
  language: 'Language',
  language_of_study: 'Language of study',
  languages: {
    ar: 'Arabic',
    en: 'English',
    es: 'Spanish',
    he: 'Hebrew',
    pt: 'Portuguese',
  },
  lastUncorrectPronounce: 'Skip to the next question.',
  lastUnitCongratulation:
    'You’ve completed Level <strong>{{currentLevel.name}}</strong><br/> and you’re on your way to Level <strong>{{nextLevel.name}}</strong>.',
  lastname: 'Last Name',
  leave_level_test_and_sign_out: 'Leave level test?',
  letsAssess:
    'Let’s check your English with a short quiz. That way, we can provide you with the best learning experience.It may take anywhere from 5-15 minutes based on your performance. Are you ready?',
  letsBegin: "Let's Begin",
  letsFinish: "Lets's Finish",
  letsGo: "Let's Go",
  letsGoFinalAssessment: 'Let’s Go',
  letsKeepLearning: "let's keep learning!",
  letsSee: "Let's see how well you know your vocabulary.",
  letsSeeIfYouReady: "Let's see if you're ready to",
  level: 'Level',
  levelAchievement: 'Level Achievement',
  levelPerSkill: 'Level Per Skill',
  levelTest: 'Level test',
  levelTestIncomplete: 'Level Test Incomplete',
  linkCopied: 'Link Copied!',
  listen_tip: 'Listen Tip',
  listeningTapToFinish: 'Listening',
  loading_spinner: 'Loading Spinner',
  loginFromDifferent: 'Log in from different account',
  logout: 'Log Out',
  looksLikeYouOnARoll: "Looks like you're on a roll",
  melingoCalculateYourLevel: 'We’re calculating your level ...',
  memoryGameTitle: 'Flip cards to find six matches between texts and images.',
  menu_button: 'Menu Button',
  message: 'Message',
  monday: 'monday',
  mondayShort: 'Mon',
  moreTime: 'more time',
  moreTimes: 'more times',
  mustFirstCreateClass:
    'In order for your students to begin using the platform, you must first create a class and then invite them to join.',
  myProfile: 'My Profile',
  myProgress: 'My Progress',
  myRecentTranslations: 'My recent translations',
  needHelp: 'Need Help?',
  newClass: 'New Class',
  new_password: 'New Password',
  new_password_verification: 'Re-Enter New Password',
  next: 'Next',
  nextQuestion: 'Next question',
  nextQuiz: 'Next activity',
  nextUpInYourLearningPath: 'Next up in your learning path',
  nextWord: 'Next Word',
  next_button: 'Next Button',
  niceEffort: 'Nice effort,',
  noCoins: 'No Coins',
  noDataToDisplay: 'No data to display',
  noExtraCoins: 'No Extra Coins',
  noExtraCoinsThisTime: 'so no extra coins this time',
  noResultsFor: 'No Results for {{word}}',
  none: 'None',
  notAvailable: 'N/A',
  notBad: 'Not Bad',
  notMadeIt: "It looks like you didn't make it this time, you can",
  notMadeItInTime:
    'It looks like you didn’t make it this time. We’re moving on to the next question!',
  ofThisSite: 'of this site.',
  ok: 'OK',
  okLetsBegin: "OK, let's begin!",
  okLetsGo: "Ok, Let's go!",
  oneMoreMistake: 'One more mistake will end the game,',
  onlyNumbersAllowed: 'Only numbers are allowed',
  oops: 'Oops',
  oopsDots: 'Oops...',
  orClickHereToSendAgain: 'Or <0>click here</0> to send the code again.',
  orClickHereToSendAgainTeacher: '<0>Didn’t receive it? Send code again</0>',
  outOf: 'out of',
  overallProgress: 'Overall Progress',
  passRate: 'Pass Rate',
  password: 'Password',
  passwordMinimumLength: '8 characters minimum length',
  password_changed_successfully: 'Password changed successfully',
  passwordsDontMatch: "Passwords don't match",
  passwords_must_match: 'Passwords must be matched',
  pause_game: 'Pause Game',
  paussed: 'Paused',
  perGame: 'Per Game',
  perLevel: 'Per Level',
  perSkill: 'Per Skill',
  phone: 'Phone',
  phraseOfTheDay: 'Phrase of the day',
  phrasesFor: 'Phrases for',
  pictureOfTheDay: 'Picture Of The Day',
  play_sound: 'Play Sound',
  pleaseChooseYourOwnPassword: 'Please choose your own password',
  pleaseCompleteTheFollowingTest:
    'Please complete the following test. Take your time, read the instructions and do your best.',
  pleaseContact:
    'Please contact {{studentName}} directly and tell them that they must log out and log back in to see the changes.',
  pleaseContactYourSchoolManagerForGuidance: 'Please contact your school manager for guidance.',
  pleaseEnter: 'Please enter',
  pleaseEnterTheFollowing: 'Please enter the following information to begin your',
  pleaseEnterYourDetails: 'Please enter your details',
  pleaseSelectYourAccount: 'Please select your account or log in from another one',
  pleaseTakeAMoment: 'Please take a moment to register your school.',
  pleaseTryAgain: 'Please try again.',
  pleaseTryAgainLaterWeApologize: 'Please try again later. We apologize for any inconvenience.',
  pleaseWriteOnlyLetters: 'Please write only letters',
  powerFulWords: 'empower, enrich, and inspire us',
  practice: 'Practice',
  practiceAgain: 'Practice again',
  practiceProperPronunciation: 'Practice proper pronunciation.',
  practiceYourVocabulary: 'Practice your vocabulary',
  practice_makes_perfect: 'Practice makes perfect!',
  preferences: 'Preferences',
  premiumSection: {
    description:
      'Lorem ipsum dolor sit amet lorem ipsum dolor sit amet lorem ipsum dolor sit ametLorem ipsum dolor sit amet',
    title: 'Try lorem ipsum for free',
    unlock: 'Unlock Premium',
  },
  preparationAudioToImage:
    'Listen up! In this fun game, you’ll hear short audio clips and then choose a picture as your response.',
  preparationAudioToImageMobile:
    'Listen up! In this fun game, you’ll hear short audio clips and then choose a picture as your response.',
  preparationClosedSpelling:
    'Spell it out! Look at the image and use the letters to spell what you see. It’s a fun game to improve your spelling skills!',
  preparationClosedSpellingMobile:
    'Spell it out! Look at the image and use the letters to spell what you see. It’s a fun game to improve your spelling skills!',
  preparationDescriptionFirstPart:
    'Listen up! In this fun game, you’ll hear short audio clips and then choose a picture as your response.',
  preparationDescriptionFirstPartMobile:
    'Listen up! In this fun game, you’ll hear short audio clips and then choose a picture as your response.',
  preparationDescriptionSecondtPart: "It's like a puzzle that helps you learn English!",
  preparationGrammar:
    "Are you ready? Complete the sentences by choosing the grammatically correct option. Let's practice your English grammar!",
  preparationGrammarMobile:
    "Are you ready? Complete the sentences by choosing the grammatically correct option. Let's practice your English grammar!",
  preparationImageToText:
    'Get ready! In this fun game, you’ll see an image and pick the answer that fits it. Boost your English skills with images!',
  preparationImageToTextMobile:
    'Get ready! In this fun game, you’ll see an image and pick the answer that fits it. Boost your English skills with Images!',
  preparationListen:
    'Listen up! In this fun game, you’ll hear a short audio clip and then pick the answer that fits it.',
  preparationListenMobile:
    'Listen up! In this fun game, you’ll hear a short audio clip and then pick the answer that fits it.',
  preparationMemory:
    'Challenge your memory! Match the words with the images. It’s a fun game to improve your English vocabulary!',
  preparationMemoryMobile:
    'Challenge your memory! Match the word cards with the fitting image cards. It’s a fun exercise to reinforce your English vocabulary!',
  preparationNaturalConversation:
    'Tune in! Listen to the audio and pick the right answer, then practice saying it. It’s like a real conversation to improve your speaking skills!',
  preparationNaturalConversationMobile:
    'Tune in! Listen to the audio and pick the right answer, then practice saying it. It’s like a real conversation to improve your speaking skills!',
  preparationOpenWriting:
    'Show off your writing! Describe the image you see and get a grade based on your writing. It’s a fun way to improve how you use English!',
  preparationOpenWritingMobile:
    'Show off your writing! Describe the image you see and get a grade based on your writing. It’s a fun way to improve how you use English!',
  preparationRead:
    "Let's go! Read the text carefully, then answer questions that follow. It’s a great way to help your understanding of English!",
  preparationReadMobile:
    "Let's go! Read the text carefully, then answer questions that follow. It’s a great way to help your understanding of English!",
  preparationSecondtPartGrammar: 'Don’t worry, you’ll get tips along the way to help.',
  preparationSecondtPartImageToText: 'Good luck!',
  preparationSecondtPartSpeak: 'Good luck!',
  preparationSecondtPartTextToImage: 'Good luck!',
  preparationSecondtPartVocabulary:
    "Let's see how well you know your vocabulary. <b>Good luck!</b>",
  preparationSpeaking:
    'Practice proper pronunciation. Using your microphone, speak slowly and clearly and make sure there is no background noise. Good luck!',
  preparationSpeakingMobile:
    'Practice proper pronunciation. Using your microphone, speak slowly and clearly and make sure there is no background noise.',
  preparationTextToImage:
    "Let's go! In this exciting game, you’ll read and then choose the matching picture from four choices. It’s a great way to link words with images!",
  preparationTextToImageMobile:
    "Let's go! In this exciting game, you’ll read and then choose the matching picture from four choices. It’s a great way to link words with images!",
  preparationTextToText:
    'Improve your vocabulary and reading skills by choosing the word that best completes the sentences.',
  preparationTextToTextMobile:
    'Enhance your reading comprehension, vocabulary, and grammar by choosing the correct answer that best completes the following sentences.',
  preparationVocabulary:
    'Practice and grow your vocabulary by identifying words and phrases in English and then choosing the correct translation.',
  preparationVocabularyMobile:
    'Practice and grow your vocabulary by identifying words and phrases in English and then choosing the correct translation.',
  preparetionAudioToImage:
    "Listen up! In this fun game, you’ll hear short audio clips and then choose a picture as your response. It's like a puzzle that helps you learn English!",
  preparetionClosedSpelling:
    'Spell it out! Look at the image and use the letters to spell what you see. It’s a fun game to improve your spelling skills!',
  preparetionDescriptionFirstPart:
    "Listen up! In this fun game, you’ll hear short audio clips and then choose a picture as your response. It's like a puzzle that helps you learn English!",
  preparetionDescriptionSecondtPart: "It's like a puzzle that helps you learn English!",
  preparetionGrammar:
    "Are you ready? Complete the sentences by choosing the grammatically correct option. Let's practice your English grammar!",
  preparetionImageToText:
    'Get ready! In this fun game, you’ll see an image and pick the answer that fits it. Boost your English skills with images!',
  preparetionListen:
    'Listen up! In this fun game, you’ll hear a short audio clip and then pick the answer that fits it.',
  preparetionMemory:
    'Challenge your memory! Match the words with the images. It’s a fun game to improve your English vocabulary!',
  preparetionNaturalConversation:
    'Tune in! Listen to the audio and pick the right answer, then practice saying it. It’s like a real conversation to improve your speaking skills!',
  preparetionOpenWriting:
    'Show off your writing! Describe the image you see and get a grade based on your writing. It’s a fun way to improve how you use English!',
  preparetionRead:
    "Let's go! Read the text carefully, then answer questions that follow. It’s a great way to help your understanding of English!",
  preparetionSpeaking:
    'Practice proper pronunciation. Using your microphone, speak slowly and clearly and make sure there is no background noise. Good luck!',
  preparetionTextToImage:
    "Let's go! In this exciting game, you’ll read and then choose the matching picture from four choices. It’s a great way to link words with images!",
  preparetionTextToText:
    'Improve your vocabulary and reading skills by choosing the word that best completes the sentences.',
  preparetionVocabulary:
    'Practice and grow your vocabulary by identifying words and phrases in English and then choosing the correct translation.',
  previous_button: 'Previous Button',
  processing: 'Processing',
  proficiency: 'Proficiency',
  profile: 'Profile',
  progress: 'Progress',
  pt: 'Portuguese',
  qs: 'Qs',
  question: 'Question',
  questions: 'Questions',
  questionsAndConcerns: 'questions and concerns.',
  questionsLeft: 'number of questions left',
  quiz: 'Activity',
  quizzes: 'Quizzes',
  quizzesCompleted: 'Games completed',
  quizzesSection: {
    comingUpQuizzesTitle: 'Coming up games',
    lastQuizzesSubTitle: 'Improve from 1/2 stars to 3 stars',
    lastQuizzesTitle: 'Improve your last games',
  },
  ran_out_of_time: 'It looks like you ran out of time again. This question will be marked as',
  rateYourself: 'Rate Yourself',
  readMore: 'Read more',
  readTheText: 'Read the text and answer the questions',
  readWithImageStartQuizInstructions: 'Read the text and answer the questions that follow',
  read_tip: 'Read Tip',
  readyToChooseYourAvatar: 'Ready to choose your avatar?',
  receiveTipsAndUpdates:
    "I'd like to receive product tips and updates from Britannica from time to time",
  receiveTipsAndUpdatesMorfix:
    "I'd like to receive product tips and updates from Morfixschool 2.0 from time to time",
  recentLogin: 'Recent Login',
  refresh: 'Refresh',
  registrationDealLimitReachedMessageDescription:
    'Please contact your school administrator for guidance.<br/><br/>Error code: LR9D2F7H',
  registrationDealLimitReachedMessageTitle: 'School Subscription Limit Reached 😕',
  rememberThis: 'Remember This?',
  remind_teacher_to_move_up: 'Remind your teacher to move you up.',
  reports: {
    activitiesCompletedInLevel: 'Activities Completed in Level {0}',
    attemptsAndHints: '📍 Attempts & Hints',
    avgActivitiesCompleted: 'Avg. Activities Completed',
    avgAttempts: '📍 Avg. Attempts',
    avgAttemptsPerActivity: 'Avg. attempts per activity',
    avgFailureRate: 'Avg. Failure Rate',
    avgHintsPerActivity: 'Avg. hints per activity',
    avgPassRate: 'Avg. Pass Rate',
    avgPerWeek: 'Avg. per week',
    avgSkillScores: '💯 Avg. Skill Scores',
    avgStreakLength: '📆 Avg. Streak Length',
    avgStreakPerWeek: 'Avg. streak per week',
    avgStudentProgress: '🚀 Avg. Student Progress',
    avgTimeSpent: '⏱️ Avg. Time Spent',
    continuousEngagement: 'Continuous Engagement',
    daysPerWeek: '{0} Days Per Week',
    engagement: '💪🏼 Engagement',
    failureRate: 'Failure Rate',
    inCurrentLevel: 'In Current Level',
    longestStreak: 'Longest streak',
    onActivities: 'On Activities',
    overallProgressInLevel: 'Overall Progress in Level {0}',
    passRate: 'Pass Rate',
    perActivity: 'Per Activity',
    perLevel: 'Per Level',
    previous7Days: 'Previous 7 days',
    progress: '🚀 ️Progress',
    remark:
      '*this data is according to <b>{0}</b> active students out of {1} students in this class.',
    streakLast7Days: 'Streak over last 7 days',
    streakLength: '📆  Streak Length',
    timeSpentOnActivites: 'Time Spent on Activites',
    total: 'Total',
    totalHintsUsed: 'Total hints used',
    totalInPrev7Days: 'Total in prev. 7 days',
    totalStudents: 'Total Students',
  },
  requirements: 'Requirements',
  resendVerificationCode: 'Didn’t receive it? Send code again',
  resultForTheTranslation: 'result for the translation of',
  results: 'Results',
  resultsForTheTranslation: 'results for the translation of',
  resume: 'Resume',
  review: 'Review',
  reward: 'Reward',
  saturday: 'saturday',
  saturdayShort: 'Sat',
  save: 'Save',
  saveChanges: 'Save Changes?',
  schoolName: 'School Name',
  score: 'Score',
  searchForAStudent: 'Search for a student',
  searchForStudent: 'Search for student',
  selectSkill: 'Select Skill',
  selfPracticeProgress: 'Self Practice Progress',
  selfPracticeStreak: 'Self Practice Streak',
  send: 'Send',
  sendNow: 'Send now',
  settings: {
    about_me: 'About Me',
    about_the_product: 'About The Product',
    add_students: {
      add_student: 'Add New Student',
      add_students: 'Add new students',
      add_students_manually: 'Add Manually',
      button_next: 'Next',
      click_to_browse: 'click to browse',
      create_students: 'The following students will receive an invitation email',
      download: 'Download',
      download_sample: 'a sample Excel (xlsx) file',
      drag_here: 'Drag a file here or ',
      email_will_be_send: 'An email with a temporary password will be sent to the student',
      enroll_students: 'Enroll students',
      enrollment_unsuccessful: '⚠️ Enrollment Unsuccessful!',
      enrollment_unsuccessful_description:
        'The following students could not enroll because their email has already been used:',
      errors: {
        bad_formatting:
          'Your file is not formatted correctly. <br/> Please check your data and make corrections as needed',
        email_in_use: 'This email address is already registered',
        email_incorrect: 'Please enter a valid email address.',
        file_empty: 'The file you are trying to upload is empty',
        file_too_large: 'The file is too large. Maximum size allowed is 256KB.',
        first_name_incorrect: 'First name contains invalid characters.',
        first_name_missing: 'First name is required.',
        last_name_incorrect: 'Last name contains invalid characters.',
        last_name_missing: 'Last name is required.',
        limit_reached:
          'The maximum number of student per class is 50. <br/> Please remove and upload a new file.',
        limit_reached_description: 'Your class contains the maximum number of students allowed.',
        limit_reached_title: 'This class is already full.',
        max_number_of_students:
          'The maximum number of student per class is 50. <br/> Please remove and upload a new file.',
        students_not_registered: 'Some students were not registered',
        wrong_format:
          'The file format is incorrect. <br/> Please ensure you are uploading an Excel (xlsx) file.',
      },
      invite_students: 'Invite Students',
      partial_success: '⚠️ Partial Success',
      partial_success_description:
        '{{addedStudents}} out of {{totalStudents}} students have successfully enrolled in this class.<br/>They have been sent an email with their temporary password.',
      preview: 'Student Roster',
      students_preview: 'Preview',
      success_description:
        "Your students have been sent an email with instructions on resetting their password. Once they've done that, they'll begin their English learning journey.",
      success_title: 'The class has been created successfully!',
      upload_attention_text:
        '⚠️ Your class contains {{curent}}/{{limit}} students.<br/>You may enroll {{certified}} more students.',
      upload_attention_text_new_class: '⚠️ Max. {{limit}} students per class',
      upload_description:
        'Once the file has been uploaded successfully, your students will be registered and receive a temporary password in their email and can log in to Hello Britannica.',
      upload_file: 'Upload your students excel file',
      upload_instruction: 'Download and view the template for guidance.',
      upload_successful: 'Your file has been uploaded successfully',
    },
    change_password: 'Change Password',
    class: 'My Class',
    current_level: 'Current Level',
    delete_class: {
      areYouSure: 'Are you sure?',
      cancel: 'Cancel',
      classNotEmpty: "You can't delete a class that contains students.",
      classNotEmptyDescription:
        "If you would like to delete this class, you must first delete those students. You can do this by opening the student's profile and clicking the Edit icon.",
      delete: 'Delete',
      deletingFailed: 'We had a technical problem.',
      deletingFailedDescription:
        'Our servers are not responding. Please try again. If the problem persists, please contact us at <a href="mailto:{{email}}">{{email}}</a>',
      description: 'You’re about to delete your class. This cannot be undone.',
      tryAgain: 'Try again',
    },
    delete_student: {
      areYouSure: 'Are you sure?',
      cancel: 'Cancel',
      choose: 'Choose',
      delete: 'Delete',
      deleteStudent: 'Delete student',
      deletingFailed: 'Deleting failed.',
      deletingFailedDescription: '',
      description:
        'Deleting this student will permanently remove them from the platform. This student will no longer be able to login and all progress data will be lost. Please provide a reason:',
      reason: 'Reason',
      reasons: {
        aClericalError: 'A clerical error',
        other: 'Other',
        requestedToBeRemoved: 'The student or parent requested to be removed',
      },
      why: 'Why?',
    },
    grade: 'Grade',
    home: 'Home',
    language: 'Language',
    logout: 'Log Out',
    my_classes: 'My Classes',
    my_games: 'My Games',
    my_students: 'My Students',
    my_tutor: 'My Tutor',
    native_language: 'My Home Language',
    privacy_policy: 'Privacy Policy',
    profile: 'Profile',
    school_id: 'My School',
    security: 'Security',
    sound: 'Sound',
    title: 'Settings',
    username: 'My Name',
  },
  showMore: 'show more',
  signIn: 'Sign in',
  signYouIn: 'Signing you in ...',
  sign_out: 'Sign Out',
  sincerely: 'Sincerely, {{name}}',
  sixDigits: '6 digits',
  sixDigitsAtLeast: 'The code must be 6 digits at least',
  skills: {
    grammar: 'Grammar',
    listen: 'Listen',
    read: 'Read',
    speak: 'Speak',
    spelling: 'Spelling',
    vocabulary: 'Vocabulary',
  },
  skillsTestTryAgain:
    'You can try again now or go back to the pervious games in this level and practice some more.',
  skip: 'Skip',
  somewhatAgree: 'Somewhat Agree',
  sorryThisLinkIsNoLongerValid: 'Sorry, this link is no longer valid',
  sortBy: 'Sort by:',
  speakNow: 'Speak now',
  speak_tip: 'Speak Tip',
  spelling_tip: 'Spelling Tip',
  stars: 'Stars',
  start: 'Start',
  startActivity: 'Start Activity',
  startLevelTest: 'Start Level Test',
  startNextQuiz: 'Start Next Quiz',
  startQuiz: 'Start game',
  startTheNextLevel: 'Start the Next Level',
  startTheNextQuiz: 'Start the Next game',
  startingReward: 'Starting reward',
  state: 'State',
  status: 'Status',
  studentActivity: 'Student Activity',
  studentInstructions: 'Student Instructions',
  studentLevels: 'Student Levels',
  studentName: 'Student Name',
  studentReport: 'Student Report',
  students: 'Students',
  studentsAreNotPermittedToAdvanceToTheNextLevel:
    'Students are not permitted to advance to the next level. If you decide to change the student’s level based on their performance, you may do so by entering the student profile and manually changing their level for each skill.',
  studentsSuccessfullyEnrolled: 'Students have been successfully enrolled!',
  study_language: 'Language Of Study',
  subject: 'Subject',
  submit: 'Submit',
  suggested_languages: 'Suggested Languages',
  sunday: 'sunday',
  sundayShort: 'Sun',
  synonyms: 'Synonyms',
  tapToSpeak: 'Tap to speak',
  tapToTranslate: 'Tap for translate...',
  teacherEndLevelTest:
    'You and your students will start speaking and spelling at the beginner level. This will make the overall level appear slightly lower. You can manually adjust the level of each student in each skill by accessing their profile on your dashboard, whereas you can access all games in all levels.',
  teacherInstructions: 'Teacher Instructions',
  teacherLetsBeginn: "Let's Begin!",
  teacherName: 'Teacher Name',
  teachers: 'Teachers',
  teachersThatHaveRegistered: 'Teachers that have registered',
  termsAndConditions: 'Terms and Conditions',
  testYourEnglish: 'Test your English level',
  thankYou: 'Thank you!',
  thanksForSubmission: 'Thank you for your submission!',
  thatsTheWay: 'That’s the way!',
  thats_the_way: 'That’s the way!',
  theGamesIn: 'the games in',
  theWordWasntPronouncedCorrectly: "The word wasn't pronounced correctly.",
  theWordsWerentPronouncedCorrectly: "The words weren't pronounced correctly.",
  thereAre: 'There are',
  thereIs: 'There is',
  thisStudentHasNot:
    'This student has not yet completed the level test. Once they have begun their journey, you will see their progress here.',
  thisStudentIsInactive:
    'This student is inactive. They have been invited to the class, but have not yet changed their temporary password and have not yet started the initial level placement test.',
  thisStudentIsLevelTestIncomplete:
    "This student has not yet completed the initial level placement test. Once they have done so, you'll be able to see their progress.",
  thisTestIsDesignedToAssessYourStudentsInTheirGeneralEnglishLevel:
    'When students have completed all the skill activities at their level, they can receive an end of level test which will assess their proficiency on all they have learned. Schedule this test using the date indicator below.',
  thisTestWillHelpYouSeeHowMuchEnglishYouHaveLearnedSoFar:
    'This test will help you see how much English you’ve learned so far. It includes grammar, vocabulary, reading, listening and speaking. After the test, you can continue to play games and keep learning.',
  thisTopicWillHelpMe: 'This topic will help me',
  thisWillHelpYouUnderstandTheMaterialBetter: 'This will help you understand the material better',
  thursday: 'thursday',
  thursdayShort: 'Thurs',
  thwWordYouEntered:
    'The word you’ve entered isn’t in the dictionary. Try to search a different word',
  timeIsUp: 'Time is up!',
  tip: 'Tip!',
  tipsAndTricks: 'Tips and Tricks',
  to: 'To',
  toImproveYourVocabulary: 'To improve your vocabulary,',
  toJoin: 'to join',
  toNextQuestion: 'to the next question',
  toViewItsOverallLearningProgress: 'to view its overall learning progress',
  toViewTheirIndividualProgress: 'to view their individual progress',
  toViewTheirOverallProgress: 'to view their overall progress',
  todayQuestion: "Today's question",
  topicProgress: 'Topic progress',
  topics: 'Topics',
  totalActivities: 'Total Activities',
  totalCoins: 'Total Coins',
  totalHintsUsed: 'Total Hints Used',
  totalQuizzes: 'Total games',
  totalWords: 'Total words',
  trasnlateAWordOrPhrase: 'Translate a word or phrase in English or in {{nativeLanguage}}...',
  tries: 'tries',
  tryAgain: 'Try again',
  tryAgainBtn: 'Try again one more time',
  tryAgainLater: 'Try again later!',
  tryAgainOneMore: 'try again 1 more time or move on',
  tryAgainOrKeepGoing: 'Try again or keep going',
  tryBetter: 'Try better next time',
  tuesday: 'tuesday',
  tuesdayShort: 'Tues',
  tutorBack: '< Back',
  tutorBannerTitle:
    'Take your learning to the next level! Based on your game play, we’ve come up with some suggestions for you to improve your knowledge of English and challenge you to succeed.',
  tutorChalangeTitle: 'Take brief study breaks for improved focus and memory.',
  tutorChallenge: 'I want a challenge',
  tutorChooseAnswer: 'Choose the correct answer to fill in the sentence:',
  tutorClickToPractice: 'Click to practice!',
  tutorContinue: 'Continue without practice',
  tutorContinueQuiz: 'Continue quiz',
  tutorCorrectAnswer: 'Show me the correct answer',
  tutorCorrectAnswerIs: 'The correct answer is "{{answer}}".',
  tutorCorrectAnswerIsShort: 'The correct answer is <strong>{{answer}}</strong>',
  tutorCorrectBody: 'You’re getting the hang of it!',
  tutorCorrectSentence: 'The correct answer is…',
  tutorCorrectTitle: 'Correct!',
  tutorEmptyDashboard:
    "Oops! It's a bit empty here... Our team is working on bringing you more content!",
  tutorEndChatOption: 'End chat and go back',
  tutorExampleOption: 'I need more example',
  tutorExerciseOption: 'Let’s exercise',
  tutorExitNo: 'Keep going',
  tutorExitText:
    "Please note that closing the Tutor will delete your current chat. Don't worry. You can always start a new chat.",
  tutorExitTitle: 'Practice In progress',
  tutorExitYes: 'End Practice',
  tutorExplainMore: 'Explore with Tutor',
  tutorFeedback: 'Would you like to give some feedback?',
  tutorGetSession: 'Get a personalized session',
  tutorGetSessionTitle: 'Get a personalized session so later will easier and more fun',
  tutorGiveExample: 'Give me more example',
  tutorGotIt: 'Got It',
  tutorHowHelp: 'How can i help you?',
  tutorInCorrectBody: 'Your answer, “{{answer}}” is wrong.',
  tutorInCorrectTitle: 'Oops. Incorrect.',
  tutorKeepGoing: "You're Great! Take brief study breaks for improved focus and memory.",
  tutorLetMeExplain: 'Let me explain…',
  tutorLetsPractice: "Let's practice before continuing?",
  tutorLetsPracticeOption: 'Let’s Practice',
  tutorLetsStart: "Let's start!",
  tutorMore: 'Practice is recommended',
  tutorMoreExplanation: 'More explanation',
  tutorMoreHelp: 'More Help',
  tutorMoreTips: 'More Tips',
  tutorNeedHint: 'I need a hint',
  tutorNewtag: 'New',
  tutorNoAndContinue: 'No, and continue',
  tutorNoMoreHelp: 'No, I need more help',
  tutorNotification: 'Quiz notification',
  tutorNotificationBack: 'Go back',
  tutorNotificationBody:
    'Turn off the option to receive explanations after each incorrect answer until the end of the quiz?',
  tutorNotificationMuted: 'The quiz notification has been muted until the end of the quiz',
  tutorNotificationOff: 'Yes, turn off',
  tutorPractice: 'Practice to perfection',
  tutorPracticeIsRecommended: 'Practice is recommended',
  tutorPracticeWithMe: 'Practice with me',
  tutorPracticeWithMeTitle: 'Practice with me to enhance your grammar skills.',
  tutorPrivate: 'Private Tutor',
  tutorPrivateTutorTip: 'Private Tutor Tip!',
  tutorShifting: "I'm getting the {{quizName}} game ready. Almost there...just a few more seconds.",
  tutorSkill: 'Skill',
  tutorStartedAt: 'Chat started at {{timeStart}}',
  tutorTryBetterTitle: 'Try better next time',
  tutorTurnOff: 'Turn off',
  tutorUser: 'User',
  tutorWasClear: 'Was that clear?',
  tutorWasHelpful: 'Was this helpful to you?',
  tutorWelcomeTitle: 'Welcome to Your Private Tutor',
  tutorYesAndContinue: 'Yes, and continue',
  tutorYesGoBack: 'Yes, go back to Quiz',
  tutor_quizzes_not_taken: 'Join Me for More Practice!',
  tutor_quizzes_taken: 'Turn Weaknesses into Strengths',
  uncorrectPronounce: 'Try again or skip to the next question.',
  understandingYourEnglishLevel: 'We are starting to understand your English level.',
  unfortunately: "Unfortunately, you didn't pass, so you won't receive any coins for this game.",
  university: 'University',
  update: 'Update',
  uploadCsvErrorModalDescription:
    'You may have some internet related issues.<br />Please try again.',
  uploadCsvErrorModalTitle: 'Upload failed',
  uponRegistrationStudentsWillReceiveAnInitialLevelTest:
    'When students log in for the first time, they will receive a level set test. Once completed, their individual learning path will be set based on their proficiency level on each skill. If disabled, the student will begin at level 1.',
  useTheCoins: "Use the coins you've earned to get hints",
  userDataSuccessfullyUpdated: 'The details have been successfully updated',
  userStreak: {
    subTitle: 'Practice every day to become a MASTER',
    title: 'Keep the streak!',
  },
  username: 'Username',
  usingYourMicrophone:
    'Using your microphone, speak slowly and clearly and make sure there is no background noise.',
  validation: {
    code_required: 'Code is required',
    confirm_password_required: 'Confirm Password is required',
    email_required: 'Email is required',
    password_required: 'Password is required',
    username_required: 'Username is required',
  },
  validationCodeInvalid: 'Validation code invalid',
  verificationCode: 'Verification Code',
  verificationCodeSent: 'A verification code has been sent to',
  viewAll: 'View all',
  viewAnswerChoices: 'View answer choices',
  viewDetails: 'View Details',
  viewPerformance: 'View Performance',
  viewProfile: 'View Profile',
  vocabularyMistakes: 'Vocabulary Mistakes',
  vocabularyPractice: 'Vocabulary practice',
  vocabulary_tip: 'Vocabulary Tip',
  wayToGo: 'Way to go!',
  weAreAnalyzing: 'We are analyzing your writing. Just a few seconds...',
  weAreSorry: 'We are sorry',
  weCouldNotProduce: 'We could not produce the report at the moment.',
  weRecommendThatYouGoBackToYourPreviousGames:
    'We recommend that you go back to your previous games and do them again.',
  weStillDontHaveAll:
    "We still don't have all the necessary information, or there is a temporary glitch.",
  weWantYourFeedback: 'Hi {{firstname}}, We want your feedback!',
  we_want_to_know: 'We want to know your level',
  wednesday: 'wednesday',
  wednesdayShort: 'Wed',
  welcome: 'Welcome',
  welcomeBack: 'Welcome back',
  welcomeConfetti: 'Welcome',
  welcomeTo: 'Welcome to',
  wellDone: 'Well Done',
  whatDidYouLearnAtThisLevel: 'What did you learn at this level?',
  whenStudentsWillHaveCompletedAParticularSkill:
    'When students complete all the skill activities at their designated level, they will be tested on what they have learned. A grade of 80% or higher will be regarded as proficient. If a student does not pass the test, they will be prompted to retake the test.',
  whenYourStudentsReceiveAPassingGrade:
    'When students receive a passing grade on the English Skills Assessment, they will be allowed to advance to the next level for that skill. Disabling this feature will prevent students from advancing to the next level.',
  with_a_score_of: 'with a score of',
  wordCount: 'Word count: {{wordCount}}',
  wordOfTheDay: 'Word of the day',
  words: 'Words',
  wordsDictionary: 'Words',
  wordsList: 'Words list',
  workHard: 'Work hard to get all the stars.',
  work_hard_to_get_all_stars: 'Work hard to get all the stars',
  wow: 'WOW',
  wow_score_all_stars: 'WOW, you got a score of <b>{{score}}%</b> and got all the stars.',
  wow_you_got_a_score_of:
    'WOW, you got a score of <b>{{score}}%</b> this time. Try again later to earn more stars.',
  writeClassNameHere: 'Write class name here',
  writeHere: 'Write Here',
  wrongAnswer: 'Wrong answer!',
  youAlmostThere: "You're almost there...",
  youAreDoingGreatLetsKeepGoing: 'You’re doing great!<br/>Let’s keep going.',
  youAreOnYourWay: "You're on your way!",
  youCanCreateMore: 'You can create more than one class',
  youCanPractice: 'you can practice words by game or by using flash cards and a list.',
  youCanTryAssessment:
    'You can now try the level {{level}} assessment that your students will receive before they can advance to the next level.',
  youCompletedTheTest: 'You completed the test',
  youCompletedThisActivity: 'You completed this activity and can move on to the next one.',
  youDidNotPass: 'You didn’t pass',
  youGot: 'You got',
  youHaveCompleted: 'You’ve completed the topic!',
  youMayNowShare: 'You may now share this link with your ',
  youPassedAll: "You've passed all",
  youPassedAllTheGamesIn: "You've passed all the games in",
  youPassedTheQuiz: 'You passed the activity with a score of <b>{{score}}%</b>! Great job!',
  youPracticed: "You've practiced. You've studied.",
  youStill: "You still didn't pass this game, so no extra coins, nor stars.",
  youWillLose: 'You will lose',
  you_can_now_practice_level: 'You can now practice level {{level}}',
  you_did_it: 'You Did It!',
  you_got_a_score_of: 'You got a score of {{score}}',
  you_got_a_score_try_again:
    'You got a score of <b>{{score}}%</b> this time. Try again later to earn more stars.',
  you_got_no_extra_stars: 'You got <b>{{score}}%</b> again.',
  you_passed_assessment: 'You passed the assessment',
  you_passed_quiz: 'You passed the game',
  your: 'Your',
  yourChangesWillAffectTheGeneralLevel: 'Your changes will affect the general level',
  yourClasses: 'Your classes',
  yourMessageHasBeenSentSuccessfully:
    'Your message has been sent successfully. Thank you for taking the time to provide us with valuable feedback!',
  yourMessageHere: 'Your message here',
  yourPasswordHere: 'Your password here',
  yourPhoneHere: 'Your Phone here',
  yourSchoolHasBeenCreated: 'Your school has been created successfully',
  yourStartingLevel: 'Your Starting Level',
  yourStreak: 'Your Streak',
  yourStudentQuiz:
    'This is the quiz that your students will take when they enter the app for the first time. Go ahead and try it now, or you can try it later.',
  yourStudentsHaveNotJoined: 'No Active Students Yet',
  yourStudentsHaveNotJoinedYet: 'Your students haven’t joined the class yet',
  yourStudentsInThisClassWillNotTakeTheInitialLevelTest:
    'Your students in this class will not take the initial level test upon registration. They will all start at Level 1 for all English skills.',
  yourStudentsWillNotReceiveAFinalExam: 'Your students will not receive a final exam.',
  yourSubjectHere: 'Your subject here',
};
