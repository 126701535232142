import classNames from './ReadContent.module.scss';
import classes from 'classnames';
import { PreparationTypeProps } from '@/types/game';
import { useEffect, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import UseScreenSize from '@/hooks/UseScreenSize';
import close from '@/assets/svg/close.svg';

import QuizContent from '@/components/QuizPage/QuizContent/QuizContent';
import QuizDetails from '@/components/QuizPage/QuizDetails/QuizDetails';
import { GameDetailsBlock } from '@/components/QuizPage/QuizPreparation/ListenPreparation/components/GameDetailsBlock';
import GoBackBtn from '@/components/GoBackBtn/GoBackBtn';

import soundPlay from '@/assets/svg/speaker.svg';
import play from '@/assets/svg/play.svg';
import pause from '@/assets/svg/pause.svg';
import { useAppSelector } from '@/store';
import { gameSelector } from '@/store/reducers/game';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

interface ReadContentProps {
  shouldStart: boolean;
  shouldViewAnswers: boolean;
  isPaused?: boolean;
  counter: number;
  emitPause?: () => void;
}

const ReadContent = ({
  selectedType,
  selectedSkill,
  selectedTopic,
  selectedQuiz,
  shouldStart,
  shouldViewAnswers,
  isPaused,
  counter,
  emitGoBack,
  emitPause,
}: ReadContentProps & PreparationTypeProps) => {
  const { isMobile, isDesktop, windowHeight, mediumHeight } = UseScreenSize();
  const { isInEndOfSkillTest, gameQuestions, currentQuestionIndex } = useAppSelector(gameSelector);
  const [shouldDisplayImageIndicator, setShouldDisplayImageIndicator] = useState(false);

  const { isLevelTest, isFinalAssessment } = useAppSelector(gameSelector);
  const navigate = useNavigate();
  const textRef = useRef<HTMLDivElement>(null);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBase64, setAudioBase64] = useState<string | null>(null);
  const [showGradient, setShowGradient] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  //FIXME: Need to be replace with correct data from store
  const currentQuestion = gameQuestions[currentQuestionIndex];
  const contentDescriptionData = currentQuestion?.newData?.data?.metadata;
  const contentSound = contentDescriptionData?.contentSound ?? null;
  const image = contentDescriptionData?.image || '';
  const copyright = image && currentQuestion?.imagesCredits?.[image];
  const imageAlt = image && currentQuestion?.imagesAlt?.[image];

  const bodyRef = useRef<HTMLDivElement>(null);
  const [offsetFromTop, setOffsetFromTop] = useState(0);
  const closedHeight = 300;
  const openHeight = 515;

  const handleScroll = () => {
    if (textRef.current) {
      const isAtBottom =
        textRef.current?.scrollTop + textRef.current?.clientHeight ===
        textRef.current?.scrollHeight;
      console.log(isAtBottom);
      if (isAtBottom) {
        setShowGradient(false);
      } else {
        setShowGradient(true);
      }
    } else {
      return;
    }
  };
  useEffect(() => {
    if (textRef.current) {
      const isAtBottom =
        textRef.current?.scrollTop + textRef.current?.clientHeight ===
        textRef.current?.scrollHeight;

      if (
        textRef.current &&
        textRef?.current?.scrollHeight > textRef?.current?.clientHeight &&
        !isAtBottom
      ) {
        setShowGradient(true);
      } else {
        setShowGradient(false);
      }
    }
  }, [textRef.current?.scrollHeight, textRef.current?.scrollTop]);

  useEffect(() => {
    const spacing = 24;

    const readBottom = document.querySelector('#read-preparation-bottom')?.clientHeight ?? 0;

    const bottomOffset = shouldStart ? (shouldViewAnswers ? openHeight : closedHeight) : readBottom;

    const offset = (bodyRef.current?.getBoundingClientRect().top ?? 0) + bottomOffset + spacing;

    setOffsetFromTop(() => (counter === 1 ? offset - 48 : offset));
  }, [shouldStart, shouldViewAnswers, counter]);

  useEffect(() => {
    setShouldDisplayImageIndicator(() => {
      if (shouldStart) {
        return false;
      } else if (!(windowHeight > mediumHeight && isMobile)) {
        return false;
      }
      return true;
    });
  }, [windowHeight, shouldStart]);

  const onToggleSound = async () => {
    isPlaying ? audioRef.current?.pause() : audioRef.current?.play();
    setHasPlayed(true);
    setIsPlaying(prevState => !prevState);
  };

  const onSoundEnded = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    if (contentSound) {
      setAudioBase64(contentSound);
    }
  }, [contentSound]);

  let leftControl = null;

  if (!isFinalAssessment) {
    leftControl = (
      <button className={classNames.pauseBtn} onClick={emitPause}>
        <SVG src={pause} />
      </button>
    );
  }

  if (isLevelTest) {
    leftControl = (
      <button
        className={classNames.pauseBtn}
        onClick={() => {
          return navigate('/dashboard/level-test', { state: true });
        }}
      >
        <SVG src={close} />
      </button>
    );
  }

  const quizDetails =
    isFinalAssessment && isDesktop ? (
      <h2 className={classNames.finalAssessmentTitle}>{selectedQuiz?.contentTitle}</h2>
    ) : isDesktop ? (
      <QuizDetails
        className={classNames.quizDetails}
        shouldDisplayImage={windowHeight < mediumHeight || (!isLevelTest && isDesktop)}
        shouldDisplayInstructions={windowHeight > mediumHeight}
        selectedType={selectedType}
        selectedSkill={selectedSkill}
        selectedTopic={selectedTopic}
        selectedQuiz={selectedQuiz}
      />
    ) : (
      <GameDetailsBlock
        selectedTopic={selectedTopic}
        selectedQuiz={selectedQuiz}
        currentQuestion={currentQuestion}
        joinTitle={true}
      />
    );

  const placeholder = !isFinalAssessment ? (
    <div style={{ width: '32px', height: '32px' }}></div>
  ) : null;

  return (
    <div
      className={classes([classNames.readContent], {
        [classNames.readContentExtend]: !shouldDisplayImageIndicator && !isDesktop,
        [classNames.levelTestReadContent]: isLevelTest && isDesktop,
        [classNames.readContentExtendLevelTest]: isLevelTest && isDesktop,
      })}
    >
      <div
        className={classes(classNames.top, {
          [classNames.topCenter]: isLevelTest && isDesktop,
          [classNames.displayBlock]: isLevelTest,
          [classNames.topFinalAssessment]: isFinalAssessment,
        })}
      >
        {isMobile && !isLevelTest && leftControl}
        {isLevelTest && isDesktop ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 className={classNames.quizNameText}>{selectedQuiz?.contentTitle}</h1>
            {audioBase64 && <audio ref={audioRef} src={audioBase64} onEnded={onSoundEnded}></audio>}

            <button className={classNames.soundBtn} onClick={onToggleSound}>
              <SVG src={isPlaying && hasPlayed ? pause : soundPlay} />
            </button>
          </div>
        ) : (
          quizDetails
        )}

        {audioBase64 && <audio ref={audioRef} src={audioBase64} onEnded={onSoundEnded}></audio>}
        {/**TODO: need to return this code when the backend add the correct soundPath */}
        {/* {!isLevelTest && (
          <button className={classNames.soundBtn} onClick={onToggleSound}>
            <SVG src={isPlaying && hasPlayed ? pause : soundPlay} />
          </button>
        )} */}
        {/**TODO: temp need to delete this code when the backend add the correct soundPath */}
        {!isLevelTest && !isInEndOfSkillTest && !isFinalAssessment ? (
          <button className={classNames.soundBtn} onClick={onToggleSound}>
            <SVG src={isPlaying && hasPlayed ? pause : soundPlay} />
          </button>
        ) : (
          placeholder
        )}
      </div>
      {/* FIXME: Need to replace with correct value */}
      {currentQuestion?.newData.data.metadata.content && (
        <div
          ref={bodyRef}
          className={classNames.body}
          style={{
            maxHeight: isMobile
              ? `calc(100vh - ${offsetFromTop}px`
              : isLevelTest
                ? `calc(100vh`
                : '',
          }}
        >
          <QuizContent
            className={classNames.quizContent}
            shouldDisplayImageIndicator={shouldDisplayImageIndicator}
          >
            {isLevelTest && (
              <div
                className={classes({
                  [classNames.mobileGradientHeight]: isMobile,
                  [classNames.desktopGradientHeight]: isDesktop,
                  [classNames.linearGradient]: showGradient,
                })}
              />
            )}
            {contentDescriptionData?.image && (
              <div
                className={classes(classNames.closedSpellingImage, {
                  [classNames.showImage]: isDesktop || !shouldStart,
                })}
              >
                <div>
                  <img src={image} alt={imageAlt} />
                  {copyright && (
                    <span className={classNames.copyright}>
                      <span>{copyright}</span>
                    </span>
                  )}
                </div>
              </div>
            )}

            <div className={classNames.text} ref={textRef} onScroll={handleScroll}>
              {/* {isInEndOfSkillTest && !isMobile && (
                <div className={classNames.endOfSkillReadTitle}>
                  <h1>{selectedQuiz.name}</h1>
                </div>
              )} */}
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(contentDescriptionData.content || ''),
                }}
              />
            </div>
          </QuizContent>
        </div>
      )}
    </div>
  );
};

export default ReadContent;
