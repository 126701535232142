import { useEffect, useState } from 'react';

let recognition: any = null;

if ('webkitSpeechRecognition' in window) {
  //TODO: Need to fix, because webkitSpeechRecognition is not defined
  //eslint-disable-next-line
  recognition = new webkitSpeechRecognition();
  recognition.continuous = true;
  recognition.lang = 'en-US';
  recognition.interimResults = true;
  recognition.maxAlternatives = 10;
}

const UseSpeechRecognition = () => {
  const [text, setText] = useState('');
  const [results, setResults] = useState<string[]>([]);
  const [isListening, setIsListening] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);
  const [isUserSpeechEmpty, setIsUserSpeechEmpty] = useState(false);
  const [interimText, setInterimText] = useState('');
  const [pauseTimeout, setPauseTimeout] = useState<number | null>(null);

  const PAUSE_DETECTION_TIME = 2000; // 2 seconds of silence

  useEffect(() => {
    if (!recognition) {
      return;
    }

    let speechDetected = false;
    let processedResults: string[] = [];

    recognition.onstart = () => {
      speechDetected = false;
      processedResults = [];
      setIsUserSpeechEmpty(false);
    };

    recognition.onspeechstart = () => {
      speechDetected = true;

      // Clear pause timeout if speech resumes
      if (pauseTimeout) {
        clearTimeout(pauseTimeout);
        setPauseTimeout(null);
      }

      setIsUserSpeechEmpty(false);
    };

    //eslint-disable-next-line
    recognition.onresult = (event: SpeechRecognitionEvent) => {
      speechDetected = true;

      let finalTranscript = '';
      let interimTranscript = '';

      for (let i = 0; i < event.results.length; i++) {
        const result = event.results[i];
        if (result.isFinal) {
          finalTranscript += result[0].transcript;
          for (let j = 0; j < result.length; j++) {
            processedResults.push(result[j].transcript);
          }
        } else {
          interimTranscript += result[0].transcript;
        }
      }

      setInterimText(interimTranscript);
      if (finalTranscript) {
        setResults(prevResults => [...prevResults, finalTranscript]);
        setText(prevText => prevText + finalTranscript);
      }

      // Reset pause detection when receiving results
      if (pauseTimeout) {
        clearTimeout(pauseTimeout);
        setPauseTimeout(null);
      }

      // Start pause detection
      setPauseTimeout(
        window.setTimeout(() => {
          recognition.stop();
          setIsListening(false);
        }, PAUSE_DETECTION_TIME)
      );
    };

    recognition.onend = () => {
      if (speechDetected && processedResults.length === 0 && !interimText) {
        setIsUserSpeechEmpty(true);
      }
      setIsListening(false);
    };

    //eslint-disable-next-line
    recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
      console.error('Speech recognition error detected: ' + event.error);
      if (event.error === 'no-speech') {
        setIsUserSpeechEmpty(true);
      }
      setIsListening(false);
    };
  }, [pauseTimeout]);

  useEffect(() => {
    if (!recognition) return;

    try {
      if (isListening) {
        recognition.start();
      } else {
        recognition.stop();
      }
    } catch (e) {
      console.error('Error starting/stopping recognition:', e);
    }
  }, [isListening]);

  const onToggle = () => {
    if (disableToggle) return;

    if (!isListening) {
      setDisableToggle(true);
      setTimeout(() => {
        setDisableToggle(false);
      }, 1000);
    }

    setIsListening(prevState => !prevState);
  };

  const onReset = () => {
    setText('');
    setResults([]);
    recognition.abort();
    setIsListening(false);
    setIsUserSpeechEmpty(false);
    setInterimText('');
  };

  return {
    text,
    interimText,
    results,
    isListening,
    onToggle,
    onReset,
    hasRecognitionSupport: !!recognition,
    isUserSpeechEmpty,
    setIsUserSpeechEmpty,
    setIsListening,
  };
};

export default UseSpeechRecognition;
