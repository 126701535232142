import React, { useRef, useEffect, useState } from 'react';
import { GameTypeProps, PronunciationProps } from '@/types/game';
import classNames from './Pronunciation.module.scss';
import SVG from 'react-inlinesvg';
import classes from 'classnames';
import soundPlay from '@/assets/svg/speaker.svg';
import pauseIcon from '@/assets/svg/pause.svg';
import PronunciationMicrophone from './PronunciationMicrophone/PronunciationMicrophone';
import UseScreenSize from '@/hooks/UseScreenSize';
import UseLocalLang from '@/hooks/UseLocalLang';
import { gameSelector } from '@/store/reducers/game';
import { useAppSelector } from '@/store';
import commonUtils from '@/utils/common';
import { studyingLanguageSelector } from '@/store/reducers/auth';

const Pronunciation = ({
  currentQuestion,
  pickedAnswer,
  allowRetry,
  emitOnRetry,
  emitOnAnswer,
  emitOnNextQuestion,
}: GameTypeProps & PronunciationProps) => {
  const { isDesktop } = UseScreenSize();
  const localLang = UseLocalLang();
  const { selectedQuiz, revealTranslation, isFinalAssessment } = useAppSelector(gameSelector);
  const [isListening, setIsListening] = useState(false);
  const studyingLanguage = useAppSelector(studyingLanguageSelector);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef<HTMLAudioElement | null>(null); // Use a ref to keep track of the audio instance

  const onPlaySound = () => {
    if (!currentQuestion.answer) return;

    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
    } else {
      const audio = new Audio(currentQuestion.answer);
      audioRef.current = audio;

      audio.play();
      setIsPlaying(true);

      audio.onended = () => {
        setIsPlaying(false);
        audioRef.current = null;
      };
    }
  };
  const checkIfIsListening = (isListening: boolean) => {
    setIsListening(isListening);
  };
  useEffect(() => {}, [revealTranslation]);
  return (
    <div className={classNames.pronunciation}>
      {isDesktop && selectedQuiz && (
        <h2
          className={classes(classNames.instructions, {
            [classNames['instructions-ltr']]: isFinalAssessment,
          })}
        >
          {commonUtils.getQuestionInstructions(currentQuestion, selectedQuiz)}
        </h2>
      )}
      <div className={classNames.content}>
        <div
          className={classNames.top}
          style={{
            direction: (studyingLanguage?.direction as React.CSSProperties['direction']) ?? 'ltr',
          }}
        >
          <h2>{currentQuestion.question}</h2>
          {currentQuestion.answer && (
            <>
              <button
                disabled={isListening}
                className={classes(classNames.soundBtn, {
                  [classNames.soundBtnDisabled]: isListening,
                })}
                onClick={onPlaySound}
              >
                <SVG className={classNames.svg} src={isPlaying ? pauseIcon : soundPlay} />
              </button>
            </>
          )}
        </div>
        {revealTranslation && <div>{currentQuestion.targetWordTranslation ?? ''}</div>}
        <PronunciationMicrophone
          currentQuestion={currentQuestion}
          pickedAnswer={pickedAnswer}
          checkIfIsListening={checkIfIsListening}
          emitOnRetry={emitOnRetry}
          emitOnAnswer={emitOnAnswer}
          emitOnNextQuestion={emitOnNextQuestion}
        />
      </div>
    </div>
  );
};

export default Pronunciation;
