import classNames from './NotFoundContent.module.scss';
import SVG from 'react-inlinesvg';
import TheButton from '@/components/TheButton/TheButton';
import { useNavigate } from 'react-router-dom';

import search from '@/assets/svg/magnifying-glass.svg';

interface NotFoundContentProps {
  error: string;
  title: string;
  description: string;
  buttonText: string;
}

const NotFoundContent = ({ error, title, description, buttonText }: NotFoundContentProps) => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/');
  };
  return (
    <div className={classNames.notFoundContent}>
      <SVG src={search} />
      <span className={classNames.error}>{error}</span>
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: description }} />
      <TheButton
        className={classNames.btn}
        text={buttonText}
        shouldRecolor={false}
        emitOnClick={handleHomeClick}
      />
    </div>
  );
};

export default NotFoundContent;
