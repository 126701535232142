import classNames from './TopicAccordion.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useEffect, useRef, useState } from 'react';

import { unitDataService } from '@/services/unit-data';
import { CanDoAnswer, TopicsActivities } from '@/types/unit-data';
import { UserState } from '@/types/user';
import Rating from '@/components/Rating/Rating';
import DetailSeperator from '@/components/DetailSeperator/DetailSeperator';
import SectionSeperator from '@/components/SectionSeperator/SectionSeperator';
import AppLoaderCircle from '@/components/AppLoaderCircle';
import upArrow from '@/assets/svg/up-arrow.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import performanceTaskIcon from '@/assets/svg/performanceTask.svg';

export interface ActivityProps {
  activityName: string;
  activityType?: string;
  achievedStars: number;
  cumulativeScore: number;
  hasProgress: boolean;
  hasScore?: boolean;
}

export interface TopicAccordionProps {
  unitId: number;
  topicId: number;
  topicName: string;
  completionPercentage: number;
  completedActivities: number;
  totalActivities: number;
  activitiesProgress: Record<string, any> | null;
  canDoAnswerId: number;
  canDoAnswer: CanDoAnswer | null;
  canDoStatement: string;
  user: UserState | null;
  level: number;
}

const TopicAccordion = ({
  unitId,
  topicId,
  topicName,
  completionPercentage,
  completedActivities,
  totalActivities,
  activitiesProgress,
  canDoAnswerId,
  canDoAnswer,
  canDoStatement,
  user,
  level,
}: TopicAccordionProps) => {
  const { t } = useTranslation();
  const [shouldExpand, setShouldExpand] = useState(false);
  const [activities, setActivities] = useState<ActivityProps[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isLoaded = useRef<boolean>(false);
  const isActive = useRef<boolean>(false);

  useEffect(() => {
    isActive.current = true;

    return () => {
      isActive.current = false;
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      if (unitId && topicId && user?.metadata?.id) {
        setIsLoading(true);

        let activitiesList: TopicsActivities = [];
        try {
          const response =
            (await unitDataService.fetchTopicActivities(user, level, unitId, topicId))?.data
              ?.data || [];
          activitiesList = response.sort((activity1, activity2) =>
            activity1.order < activity2.order ? -1 : activity1.order < activity2.order ? 1 : 0
          );
        } catch (ex) {}

        if (!isActive.current) {
          return;
        }

        const activities: ActivityProps[] = activitiesList.map(activity => {
          const activityProgress = activitiesProgress?.[activity.id] || null;

          const activityName: string = activity.title;
          const achievedStars: number = activityProgress?.cumulativeAchievedStars || 0;
          const cumulativeScore: number = activityProgress?.cumulativeScore || 0;
          const hasProgress: boolean = !!activityProgress;
          const hasScore: boolean = activity.hasScore;

          return {
            activityName,
            achievedStars,
            cumulativeScore,
            hasProgress,
            hasScore,
          };
        });

        setActivities(activities);
        setIsLoading(false);
      }
    };

    if (!isLoaded.current && shouldExpand) {
      isLoaded.current = true;
      init();
    }
  }, [shouldExpand, unitId, user, level, topicId, activitiesProgress]);

  const onToggle = () => {
    setShouldExpand(prevState => !prevState);
  };

  const activitiesList = (activities || []).map((activity, index) => {
    const { activityName, activityType, achievedStars, cumulativeScore, hasProgress, hasScore } =
      activity;
    const scoreText = hasScore ? cumulativeScore : t('completed');

    return (
      <div key={index} className={classNames.activity}>
        <div className={classNames.content}>
          <span className={classNames.order}>{index + 1}.</span>
          <div className={classNames.partOne}>
            <span className={classNames.name}>
              {activityType === 'Performance Task' && (
                <SVG className={classNames.performanceTask} src={performanceTaskIcon} />
              )}
              {activityName}
            </span>
            <Rating
              className={classNames.rating}
              achievedStars={achievedStars}
              showEmptyStars={false}
            />
          </div>
          <div className={classNames.partTwo}>
            <span className={classNames.type}>{activityType}</span>
            {hasProgress && activityType && <DetailSeperator className={classNames.seperator} />}
            {hasProgress && <span className={classNames.score}>{scoreText}</span>}
          </div>
        </div>
        <SectionSeperator className='my-2' />
      </div>
    );
  });

  const arrowSrc = shouldExpand ? upArrow : downArrow;

  return (
    <div
      className={classes(classNames.topicAccordion, {
        [classNames.open]: shouldExpand,
      })}
    >
      <div className={classNames.top}>
        <div>
          <div className={classNames.nameAndPercentage}>
            <span className={classNames.name}>{topicName + ' '}</span>
            <span className={classNames.percentage}>| {completionPercentage}%</span>
          </div>
          <button className={classNames.arrow} onClick={onToggle}>
            <SVG src={arrowSrc} />
          </button>
        </div>
        <div>
          <span
            className={classNames.totalActivities}
          >{`${completedActivities}/${totalActivities} ${t('totalActivities')}`}</span>
          <div className={classNames.progress}>
            <div className={classNames.bar} style={{ width: `${completionPercentage}%` }}></div>
          </div>
        </div>
      </div>
      <div className={classNames.body}>
        <div className={classNames.activities}>
          {isLoading ? (
            <div className={classNames.loader}>
              <AppLoaderCircle />
            </div>
          ) : (
            <>
              {activitiesList}
              <div className={classNames.canDo}>
                <div className={classNames.partOne}>
                  <span className={classNames.canDoLabel}>{t('canDo')}</span>
                  <DetailSeperator className={classNames.seperator} />
                  <span className={classNames.canDoStatement}>“{canDoStatement}“</span>
                </div>
                <div className={classNames.partTwo}>
                  {canDoAnswer && (
                    <img className={classNames.canDoAnswerImg} src={canDoAnswer.iconSrc} />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicAccordion;
