import NotFoundContent from '@/components/NotFoundContent/NotFoundContent';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const title = location.state?.title ?? t('pageNotFound');
  const description = location.state?.description ?? t('couldNotLocate');
  const buttonText = t('homepage');
  const errorText = t('notFoundError');

  return (
    <div className='not-found h-full'>
      <NotFoundContent
        error={errorText}
        title={title}
        description={description}
        buttonText={buttonText}
      />
    </div>
  );
};

export default NotFound;
