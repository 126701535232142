import classNames from './ImproveActivity.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store';
import { authSelector } from '@/store/reducers/auth';
import UseScreenSize from '@/hooks/UseScreenSize';
import QuizBadge from '../QuizBadge/QuizBadge';
import medalGold from '@/assets/svg/medal-gold.svg';
import QuizScore from '../QuizScore/QuizScore';
import rightArrow from '@/assets/svg/right-arrow.svg';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';
import { gameActions } from '@/store/reducers/game';
import { ImprovableRecentActivity } from '@/types/progress';
import { skillService } from '@/services/';
import { unitService } from '@/services/unit';

const ImproveActivity = () => {
  const { t } = useTranslation();
  const { isDesktop } = UseScreenSize();
  const { user } = useAppSelector(authSelector);
  const { improvableRecentActivities } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getQuizByActivity = async (activity: ImprovableRecentActivity) => {
    try {
      const { level, unitId, topicId, activityId } = activity;

      const [topicResponse, unitResponse] = await Promise.all([
        skillService.fetchGameTopic(user, level, unitId, topicId),
        unitService.fetchUnitTopics(user, level, unitId),
      ]);

      const quiz = topicResponse?.data?.data?.find((obj: { id: number }) => obj.id === activityId);
      const topic = unitResponse?.data?.data?.find((obj: { id: number }) => obj.id === topicId);

      if (!quiz || !topic) {
        console.error('Quiz or Topic not found');
        return;
      }

      dispatch(gameActions.setSelectedTopic(topic));
      dispatch(gameActions.setSelectedQuiz(quiz));
      navigate('/dashboard/quiz');
    } catch (error) {
      console.error('Error in getQuizByActivity:', error);
    }
  };

  return (
    <div className={classNames.improveActivity}>
      {improvableRecentActivities && isDesktop && (
        <>
          <h2>{t('improveYourLastActivities')}</h2>
          <span className={classNames.description}>{t('tryToGetAllStars')}</span>
          <ul className={classNames.activityList}>
            {improvableRecentActivities.map((activity, index) => (
              <li
                key={activity.activityId}
                className={classNames.activityItem}
                onClick={() => getQuizByActivity(activity)}
              >
                <div className={classNames.activityDetails}>
                  <div className={classNames.medalIcon}>
                    <QuizBadge img={medalGold} isLocked={false} index={index} isExtraSmall={true} />
                    <QuizScore
                      isLocked={false}
                      achievedStars={activity.achievedStars ?? 0}
                      isExtraSmall={true}
                    />
                  </div>
                  <span className={classNames.activityName}>{activity.activityName}</span>
                  <span className={classNames.verticalSeparator}>|</span>
                  <span className={classNames.topicName}>{activity.topicName}</span>
                </div>
                <span className={classNames.arrowIcon}>
                  <SVG src={rightArrow} />
                </span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default ImproveActivity;
