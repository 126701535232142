import classNames from './PopUpSummary.module.scss';
import classes from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/store';
import { GameTypes, NewGameTypes, PopUpSummaryProps } from '@/types/game';
import { useState } from 'react';
import UseScreenSize from '@/hooks/UseScreenSize';
import { gameSelector } from '@/store/reducers/game';

import QuizSummary from './QuizSummary/QuizSummary';
import PracticeSummary from './PracticeSummary/PracticeSummary';
import commonUtils from '@/utils/common';

const PopUpSummary = ({
  className,
  selectedSkill,
  selectedTopic,
  selectedQuiz,
  coins,
  tip,
  userData,
  correctAnswers,
  totalQuestions,
  hasNextQuiz,
  emitOnClosePopUp,
  emitOnExit,
  emitOnAknowledge,
  emitStartQuizFromPractice,
  emitPracticeAgain,
  emitOnQuit,
  emitOnEndOfSkillTest,
  emitOnExitWithCanDo,
}: PopUpSummaryProps) => {
  const { t } = useTranslation();
  const [shouldExpand, setShouldExpand] = useState(false);
  const { isMobile } = UseScreenSize();
  const { gameType: gameTypeReal, quizQuestionResultData } = useAppSelector(gameSelector);

  const isOpenWriting = gameTypeReal === NewGameTypes.OpenWriting;
  let averageScore: number | null = null;
  let noGrading: boolean = false;

  const scoredAnswers = quizQuestionResultData.filter(answer => answer.hasScore);
  const totalQuestionsWithScores = scoredAnswers.length;
  const correctAnswersCount = scoredAnswers.filter(answer => answer.isCorrect).length;

  if (isOpenWriting) {
    const gradedResults = quizQuestionResultData.filter(
      result => result.openWritingResult?.scoreResponse?.noGrading !== true
    );

    if (gradedResults.length === 0) {
      noGrading = true;
      averageScore = null;
    } else {
      const scores: number[] = gradedResults
        .map(result => result.openWritingResult?.scoreResponse?.overallScore)
        .filter((score): score is number => score != null);

      const totalScores: number = scores.reduce((sum, score) => sum + score, 0);

      averageScore = scores.length > 0 ? totalScores / scores.length : null;
    }
  } else {
    averageScore = +commonUtils.calcPercentage(correctAnswersCount, totalQuestionsWithScores);
  }

  const isPractice = gameTypeReal === GameTypes.Practice;

  return (
    <div
      className={classes(
        {
          container: isMobile,
        },
        className,
        classNames.popUpSummary
      )}
    >
      {shouldExpand && isMobile && <div className={classNames.backdrop}></div>}
      {isMobile && (
        <div className={classNames.exitBtn}>
          <button onClick={emitOnExit}>{t('exit')}</button>
        </div>
      )}
      {isPractice ? (
        <PracticeSummary
          emitOnAknowledge={emitOnAknowledge}
          emitStartQuizFromPractice={emitStartQuizFromPractice}
          emitPracticeAgain={emitPracticeAgain}
          emitOnQuit={emitOnQuit}
        />
      ) : (
        <QuizSummary
          selectedQuiz={selectedQuiz}
          selectedTopic={selectedTopic}
          selectedSkill={selectedSkill}
          coins={coins}
          tip={tip}
          hasNextQuiz={hasNextQuiz}
          userData={userData}
          correctAnswers={correctAnswers}
          totalQuestions={totalQuestions}
          totalScore={averageScore}
          isOpenWriting={isOpenWriting}
          noGrading={noGrading}
          emitOnClosePopUp={emitOnClosePopUp}
          emitOnEndOfSkillTest={emitOnEndOfSkillTest}
          emitOnExit={emitOnExit}
          emitOnExitWithCanDo={emitOnExitWithCanDo}
        />
      )}
    </div>
  );
};

export default PopUpSummary;
