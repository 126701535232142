import React, { useEffect, useMemo, useState, useRef } from 'react';
import useGroupDetails from '@/hooks/UseGroupDetails';
import commonUtils from '@/utils/common';
import { useAppDispatch, useAppSelector } from '@/store';
import { authActions, authSelector } from '@/store/reducers/auth';
import { teacherActions, teacherSelector } from '@/store/reducers/teacher';
import {
  teacherDashboardActions,
  teacherDashboardSelector,
} from '@/store/reducers/teacher-dashboard';
import { ClassConfig, TeacherGroup, UserState } from '@/types/user';
import {
  GroupStudentProgress,
  StudentsSkillsProgress,
  StudentMetadata,
  UnitProgress,
} from '@/types/progress';
import { StudentsRegisteredResponse } from '@/types/register';
import { StudentData } from '@/types/student-data';
import { CreateCsvStudentsResponse, GroupStudentsResponse } from '@/types/response';
import { registerService } from '@/services/register';
import { progressService, userService } from '@/services';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDrawer } from '@/context/DrawerContext';
import MyStudents from '../main/MyStudents';
dayjs.extend(utc);

export interface existingStudentsInterface {
  totalStudents: number;
  addedStudents: number;
  existingStudents: number;
  existingStudentsList: {
    firstName: string;
    lastName: string;
    email: string;
    error: string;
  }[];
}

export interface UseMyClassesInterface {
  selectedFilter: string;
  selectedFilterText: string;
  allClassrooms: TeacherGroup[];
  filterdClassrooms: TeacherGroup[];
  classConfig: ClassConfig | null;
  onCreateClass: () => void;
  createOrSaveClass: (newConfig: ClassConfig) => Promise<void>;
  onFilter: (filter: string) => void;
  onSelectClass: (classroom: TeacherGroup) => void;
  showClassCreatedModal: boolean;
  showExistingStudentsModal: existingStudentsInterface | null;
  onCloseClassCreatedModal: () => void;
  onCloseExistingStudentsModal: () => void;
  showCopyModal: boolean;
  setShowCopyModal: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseCopyModal: () => void;
  onCloseCancelModal: () => void;
  onShowCopyModal: () => void;
  showCancelModal: boolean;
  setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  onContinue: () => void;
  onShowCancelModal: () => void;
  user: UserState | null;
  loadingGroupDetails: boolean;
  isSavingGroup: boolean;
  isCsvLoading: boolean;
  isCsvSending: boolean;
  setIsCsvLoading: React.Dispatch<React.SetStateAction<boolean>>;
  csvFilesAdded: boolean;
  setCsvFilesAdded: React.Dispatch<React.SetStateAction<boolean>>;
  showUploadCsvModal: boolean;
  addStudentsToNewGroup: boolean;
  showStudentsTableModal: boolean;
  onShowUploadCsvModal: (isNewGroup?: boolean) => void;
  onCloseUploadCsvModal: () => void;
  onShowStudentsTableModal: () => void;
  onBackStudentsTableModal: () => void;
  studentsData: StudentData[];
  setStudentsData: React.Dispatch<React.SetStateAction<StudentData[]>>;
  onCsvStudentsCreate: () => Promise<void>;
  disableSubmitButton: boolean;
  setDisableSubmitButton: React.Dispatch<React.SetStateAction<boolean>>;
  showNewStudentInput: boolean;
  setShowNewStudentInput: React.Dispatch<React.SetStateAction<boolean>>;
  classStudentsExtraDataResponse: Map<string, StudentsRegisteredResponse>;
  currentClassroom: TeacherGroup | null;
  isLoading: boolean;
  fetchDataError: boolean;
  refreshStudentsData: () => void;
  studentsProgress: GroupStudentProgress[];
  statistics: StudentsSkillsProgress | null;
  selectedStudent: GroupStudentProgress | null;
  isClassEdit: boolean;
  setIsClassEdit: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  onShowStudentModal: (studentId: string) => Promise<void>;
  onHideStudentModal: () => void;
  isStudentDataLoading: boolean;
  showStudentDataModal: boolean;
  selectedStudentData: StudentMetadata | null;
  showStudentEditModal: boolean;
  onShowStudentEditModal: () => void;
  onCloseStudentEditModal: () => void;
  editStudent: (firstName: string, lastName: string, language: string) => Promise<void>;
  showUnitData: boolean;
  onShowUnitData: (unitId: number) => void;
  onHideUnitData: () => void;
  selectedUnit: UnitProgress | undefined | null;
  showCsvStudentsCreateError: boolean;
  setShowCsvStudentsCreateError: React.Dispatch<React.SetStateAction<boolean>>;
  onStudentDelete: (reason: string) => void;
  isStudentDeleting: boolean;
  showStudentDeleteError: boolean;
  setShowStudentDeleteError: React.Dispatch<React.SetStateAction<boolean>>;
  isClassDeleting: boolean;
  showClassDeleteError: boolean;
  setShowClassDeleteError: React.Dispatch<React.SetStateAction<boolean>>;
  onClassDelete: (reason?: string) => void;
}

const UseMyClasses = (): UseMyClassesInterface => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isAddNewStudentDrawerOpen } = useDrawer();
  const { user } = useAppSelector(authSelector);
  const { isNew, selectedClassId } = useAppSelector(teacherDashboardSelector);
  const groupDetails = useGroupDetails();
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState('*');
  const [classConfig, setClassConfig] = useState<ClassConfig | null>(null);
  const [prevClassConfig, setPrevClassConfig] = useState<ClassConfig | null>(null);
  const [currentClassroom, setCurrentClassroom] = useState<TeacherGroup | null>(null);
  const [showClassCreatedModal, setShowClassCreatedModal] = useState(false);
  const [showExistingStudentsModal, setShowExistingStudentsModal] =
    useState<existingStudentsInterface | null>(null);
  const [showUploadCsvModal, setShowUploadCsvModal] = useState(false);
  const [addStudentsToNewGroup, setAddStudentsToNewGroup] = useState(false);
  const [showStudentsTableModal, setShowStudentsTableModal] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isCsvSending, setIsCsvSending] = useState(false);
  const [csvFilesAdded, setCsvFilesAdded] = useState<boolean>(false);
  const [showCsvStudentsCreateError, setShowCsvStudentsCreateError] = useState<boolean>(false);

  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [studentsData, setStudentsData] = useState<StudentData[]>([]);
  const [classStudentsExtraDataResponse, setClassStudentsExtraDataResponse] = useState<
    Map<string, StudentsRegisteredResponse>
  >(new Map<string, StudentsRegisteredResponse>());
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [showNewStudentInput, setShowNewStudentInput] = useState(false);

  const { students: studentsProgress } = useAppSelector(teacherSelector);
  const [statistics, setStatistics] = useState<StudentsSkillsProgress | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchDataError, setFetchDataError] = useState<boolean>(false);
  const [selectedStudent, setSelectedStudent] = useState<GroupStudentProgress | null>(null);
  const [isClassEdit, setIsClassEdit] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const selectedClassIdRef = useRef<string | null>(null);

  const [isStudentDataLoading, setIsStudentDataLoading] = useState<boolean>(false);
  const [showStudentDataModal, setShowStudentDataModal] = useState<boolean>(false);
  const [selectedStudentData, setSelectedStudentData] = useState<StudentMetadata | null>(null);
  const [showStudentEditModal, setShowStudentEditModal] = useState<boolean>(false);

  const [showUnitData, setShowUnitData] = useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState<UnitProgress | undefined | null>();

  const allClassrooms = useMemo(
    () => [...(user?.metadata?.organizationDetails?.teacher?.teacherGroups || [])],
    [user?.metadata?.organizationDetails?.teacher?.teacherGroups]
  );
  const filterdClassrooms = useMemo(
    () =>
      allClassrooms.filter(i => {
        if (selectedFilter === '*') {
          return true;
        }
        if (selectedFilter === 'university' || selectedFilter === 'none') {
          return selectedFilter === i.grade;
        }
        return selectedFilter === parseInt(i.grade).toString();
      }),
    [allClassrooms, selectedFilter]
  );

  const onFilter = (filter: string) => {
    setSelectedFilter(filter);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const onShowCopyModal = () => {
    scrollToTop();
    setShowCopyModal(true);
  };

  const onShowCancelModal = () => {
    scrollToTop();
    setShowCancelModal(true);
  };

  const onCloseCancelModal = (setPrevClass: boolean = true) => {
    scrollToTop();
    setShowCancelModal(false);
    if (setPrevClass && prevClassConfig) {
      setClassConfig(prevClassConfig);
      setPrevClassConfig(null);
    }
  };

  const onContinue = () => {
    setShowCancelModal(false);
  };

  const onCloseCopyModal = () => {
    setShowCopyModal(false);
  };

  const onCloseClassCreatedModal = () => {
    setShowClassCreatedModal(false);
  };

  const onCloseExistingStudentsModal = () => {
    setShowExistingStudentsModal(null);
  };

  const onShowUploadCsvModal = (isNewGroup: boolean = false) => {
    scrollToTop();
    setAddStudentsToNewGroup(isNewGroup);
    setShowUploadCsvModal(true);
  };

  const onCloseUploadCsvModal = () => {
    setShowUploadCsvModal(false);
    setCsvFilesAdded(false);
  };

  const onShowStudentsTableModal = () => {
    scrollToTop();
    setShowUploadCsvModal(false);
    setShowStudentsTableModal(true);
  };

  const onBackStudentsTableModal = () => {
    setCsvFilesAdded(false);
    setStudentsData([]);
    setShowStudentsTableModal(false);
    setShowUploadCsvModal(true);
  };

  const createOrSaveClass = async (newConfig: ClassConfig) => {
    // Update group
    if (classConfig?.id) {
      try {
        await groupDetails.saveGroupDetails(classConfig.id, {
          allowIndividualAdvancement: newConfig?.individualAdvancement ?? false,
          groupName: newConfig.name ?? '',
          grade: newConfig.grade ?? '',
          finalAssessment: {
            isActivated: newConfig.finalAssessment ?? false,
            date: dayjs(newConfig.finalAssessmentDate?.toDate())
              .utcOffset(0, true)
              .format('YYYY-MM-DD'),
          },
        });

        setClassConfig(prev => {
          return {
            ...prev,
            name: newConfig?.name,
            grade: newConfig?.grade,
            individualAdvancement: newConfig?.individualAdvancement ?? false,
            finalAssessment: newConfig.finalAssessment ?? false,
            finalAssessmentDate: newConfig.finalAssessmentDate,
          };
        });

        scrollToTop();
      } catch (e) {
        console.log(e);
      }
    } else {
      // create new group
      try {
        const newGroup = await groupDetails.createGroup({
          allowIndividualAdvancement: newConfig?.individualAdvancement ?? false,
          initalLevelTest: newConfig?.levelTest ?? false,
          groupName: newConfig.name ?? '',
          grade: newConfig.grade ?? '',
          finalAssessment: {
            isActivated: newConfig?.finalAssessment ?? false,
            date: dayjs(newConfig.finalAssessmentDate?.toDate())
              .utcOffset(0, true)
              .format('YYYY-MM-DD'),
          },
        });

        setPrevClassConfig(null);
        dispatch(teacherDashboardActions.onEdit(newGroup?.groupId ?? ''));
        selectedClassIdRef.current = newGroup?.groupId ?? '';
        setCurrentClassroom({
          id: newGroup?.groupId || '',
          name: newGroup?.groupData?.groupName || '',
          grade: newGroup?.groupData?.grade || '',
          totalStudents: 0,
          groupLevel: '',
          invitationLinkForStudents: '',
        });
        fetchStudentsData(user, newGroup?.groupId ?? '');

        setClassConfig(prev => {
          return {
            ...prev,
            id: newGroup?.groupId,
            name: newConfig?.name,
            grade: newConfig?.grade,
            finalAssessment: newConfig?.finalAssessment ?? false,
            finalAssessmentDate: newConfig?.finalAssessmentDate,
            individualAdvancement: newConfig?.individualAdvancement ?? false,
            invitationLinkForStudents: newGroup?.invitationLink ?? '',
            levelTest: newConfig?.levelTest ?? false,
          };
        });

        onShowUploadCsvModal(true);
      } catch (e) {}
    }
  };

  const onCreateClass = () => {
    setClassConfig(prev => {
      setPrevClassConfig(prev);

      const classConfigObj = {
        id: '',
        name: '',
        grade: '',
        groupLevel: '',
        invitationLinkForStudents: '',
        levelTest: true,
        individualAdvancement: true,
        finalAssessment: false,
      } as ClassConfig;

      return classConfigObj;
    });

    if (!isNew) {
      dispatch(teacherDashboardActions.onAdd());
    }
  };

  const onSelectClass = (classroom: TeacherGroup | null) => {
    if (!classroom) {
      setCurrentClassroom(null);
      setClassConfig(null);
      setStatistics(null);
      setClassStudentsExtraDataResponse(new Map<string, StudentsRegisteredResponse>());
      dispatch(teacherActions.setStudents([]));
      dispatch(teacherDashboardActions.onReset());
      return;
    }

    const { id, name, grade, groupLevel, invitationLinkForStudents } = classroom;
    selectedClassIdRef.current = id;
    setCurrentClassroom(classroom);
    setClassConfig({
      id,
      name,
      grade,
      groupLevel,
      invitationLinkForStudents,
    });
    fetchStudentsData(user, id);

    if (!selectedClassId) {
      dispatch(teacherDashboardActions.onEdit(classroom.id));
    }
  };

  const onCsvStudentsCreate = async () => {
    setIsCsvSending(true);
    try {
      const createCsvStudentsResponse = await registerService.createCsvStudents(
        user,
        classConfig?.id,
        studentsData
      );
      dispatch(
        authActions.updatedClassroomStudentsAmount({
          id: classConfig?.id ?? '',
          amount: createCsvStudentsResponse?.data.data.registeredStudents.length ?? 0,
        })
      );

      const addedStudents = createCsvStudentsResponse?.data?.data?.registeredStudents?.length || 0;
      const existingStudents =
        createCsvStudentsResponse?.data?.data?.unregisteredStudents?.length || 0;
      const totalStudents = studentsData?.length || 0;

      setStudentsData([]);
      setCsvFilesAdded(false);
      setShowStudentsTableModal(false);

      if (existingStudents > 0) {
        setShowExistingStudentsModal({
          addedStudents: addedStudents,
          existingStudents: existingStudents,
          totalStudents: totalStudents,
          existingStudentsList: createCsvStudentsResponse
            ? createCsvStudentsResponse.data.data.unregisteredStudents.map(student => {
                return {
                  firstName: student.firstName,
                  lastName: student.lastName,
                  email: student.email,
                  error: student.error,
                };
              })
            : [],
        });
      } else {
        setShowClassCreatedModal(true);
      }
    } catch (err) {
      console.error('Error creating CSV students:', err);
      setShowCsvStudentsCreateError(true);
    } finally {
      setIsCsvSending(false);
      fetchStudentsData(user, classConfig?.id ?? '');
    }
  };

  const fetchClassIdRef = useRef<string | null>();

  const fetchClassProgress = async (user: UserState | null, classId: string | null) => {
    if (!(classId && user)) {
      return;
    }

    try {
      const response = (await progressService.fetchClassProgress(user, classId))?.data.data;

      if (fetchClassIdRef.current !== classId) {
        return;
      }

      if (response) {
        const mappedStudents = response.studentsInGroup.reduce<{
          students: GroupStudentProgress[];
          validStudents: number;
          levelSum: number;
          scoreSum: number;
          progressSum: number;
        }>(
          (mappedStudents, student) => {
            const { completedActivities, totalActivities, aVGScore } = student.progress ?? {};

            const activitiesProgress = commonUtils.calcPercentage(
              completedActivities ?? 0,
              totalActivities ?? 0
            );

            mappedStudents.students.push({
              ...student,
              progress: {
                ...student.progress,
                activitiesProgress,
              },
            });

            if (student.level) {
              mappedStudents.validStudents++;
              mappedStudents.levelSum += student.level;
              mappedStudents.scoreSum += aVGScore ?? 0;
              mappedStudents.progressSum += activitiesProgress;
            }

            return mappedStudents;
          },
          {
            students: [],
            validStudents: 0,
            levelSum: 0,
            scoreSum: 0,
            progressSum: 0,
          }
        );

        dispatch(teacherActions.setStudents(mappedStudents.students as GroupStudentProgress[]));
        setStatistics(
          response.studentsProgressByLevelThenSkill?.All
            ? response.studentsProgressByLevelThenSkill?.All
            : mappedStudents.validStudents
              ? {
                  level: commonUtils.roundNumber(
                    mappedStudents.levelSum / mappedStudents.validStudents,
                    0
                  ),
                  score: commonUtils.roundNumber(
                    mappedStudents.scoreSum / mappedStudents.validStudents,
                    2
                  ),
                  progress: commonUtils.roundNumber(
                    mappedStudents.progressSum / mappedStudents.validStudents,
                    2
                  ),
                }
              : null
        );
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const fetchStudentsList = async (user: UserState | null, classId: string | null) => {
    if (user?.metadata.id && classId) {
      try {
        const studentsResponse = await registerService.getGroupStudents(user?.metadata.id, classId);

        if (fetchClassIdRef.current !== classId) {
          return;
        }

        const dataMap = (studentsResponse?.data?.data || []).reduce((map, data) => {
          if (data.studentId) {
            map.set(data.studentId, data);
          }
          return map;
        }, new Map<string, StudentsRegisteredResponse>());

        setClassStudentsExtraDataResponse(dataMap);
      } catch (err) {
        console.error('Error fetching students:', err);
        throw err;
      }
    }
  };

  const fetchStudentsData = async (user: UserState | null, classId: string | null) => {
    setIsLoading(true);
    setFetchDataError(false);
    fetchClassIdRef.current = classId;

    const result = await Promise.allSettled([
      fetchClassProgress(user, classId),
      fetchStudentsList(user, classId),
    ]);

    if (fetchClassIdRef.current !== classId) {
      return;
    }

    if (result[0].status === 'rejected' || result[1].status === 'rejected') {
      setFetchDataError(true);
      dispatch(teacherActions.setStudents([]));
      setStatistics(null);
      setClassStudentsExtraDataResponse(new Map<string, StudentsRegisteredResponse>());
    }

    setIsLoading(false);
  };

  const refreshStudentsData = () => {
    fetchStudentsData(user, classConfig?.id ?? '');
  };

  const selectedFilterText = selectedFilter === '*' ? t('all') : selectedFilter;

  useEffect(() => {
    if (isNew) {
      onCreateClass();
    } else if (user?.metadata?.organizationDetails?.teacher?.teacherGroups?.length) {
      const teacherGroups = user?.metadata?.organizationDetails?.teacher?.teacherGroups;
      const classToEdit = selectedClassIdRef.current
        ? teacherGroups.find(group => group.id === selectedClassIdRef.current)
        : teacherGroups[0];

      if (classToEdit) {
        onSelectClass(classToEdit);
      }
    }
  }, [user]);

  const onShowStudentModal = async (studentId: string) => {
    setIsStudentDataLoading(true);
    setShowStudentDataModal(true);

    try {
      const student = studentsProgress.find(student => student.id === studentId);

      if (student) {
        if (student.level) {
          const response = (
            await progressService.fetchStudentProgress(user, studentId, student.level ?? 0)
          )?.data.data;

          if (response) {
            setSelectedStudent(student);
            setSelectedStudentData(response);
          }
        } else {
          setSelectedStudent(student);
          setSelectedStudentData(null);
        }
      }
    } catch (error) {
      console.log(error);
      setShowStudentDataModal(false);
    } finally {
      setIsStudentDataLoading(false);
    }
  };

  const onHideStudentModal = () => {
    setShowStudentDataModal(false);
    onCloseStudentEditModal();
    setSelectedStudent(null);
    onHideUnitData();
    setSelectedUnit(null);
  };

  const onShowStudentEditModal = () => {
    setShowStudentEditModal(true);
  };

  const onCloseStudentEditModal = () => {
    setShowStudentEditModal(false);
  };

  const editStudent = async (firstName: string, lastName: string, language: string) => {
    if (!selectedStudent) {
      return;
    }

    setIsStudentDataLoading(true);

    try {
      if (selectedStudent.id) {
        //debugger;

        const response = (
          await userService.updateStudentSettings(
            user,
            selectedStudent.id,
            firstName,
            lastName,
            language
          )
        )?.data.data;

        if (response) {
          setSelectedStudent(prevState => {
            return prevState
              ? {
                  ...prevState,
                  firstName,
                  lastName,
                }
              : prevState;
          });

          setSelectedStudentData(prevState => {
            return prevState
              ? {
                  ...prevState,
                  studentData: {
                    ...prevState.studentData,
                    firstName,
                    lastName,
                    interfaceLanguage: language,
                  },
                }
              : prevState;
          });

          fetchStudentsData(user, classConfig?.id ?? '');
        }
      }

      setIsStudentDataLoading(false);
    } catch (error) {
      setIsStudentDataLoading(false);
      throw error;
    }
  };

  const onShowUnitData = (unitId: number) => {
    const unit = selectedStudentData?.progress.unitsProgress.find(unit => unit.unitId === unitId);
    setSelectedUnit(unit);
    setShowUnitData(!!unit);
  };

  const onHideUnitData = () => {
    setShowUnitData(false);
  };

  const [isStudentDeleting, setIsStudentDeleting] = useState<boolean>(false);
  const [showStudentDeleteError, setShowStudentDeleteError] = useState<boolean>(false);
  const onStudentDelete = async (reason: string) => {
    if (!user || !classConfig?.id || !selectedStudent || isStudentDeleting) {
      return;
    }

    setIsStudentDeleting(true);

    let isDeleted: boolean | undefined = false;
    try {
      isDeleted = (await userService.deleteStudent(user, selectedStudent.id, reason))?.data.data;
    } catch (error) {
      console.log('deleteStudent error', error);
    }

    if (!isDeleted) {
      setShowStudentDeleteError(true);
      setIsStudentDeleting(false);
      return;
    }

    const classId = classConfig.id;

    try {
      dispatch(
        authActions.updatedClassroomStudentsAmount({
          id: classId,
          amount: -1,
        })
      );

      fetchStudentsData(user, classId);
    } catch (error) {
      console.log(error);
    } finally {
      onHideStudentModal();
      setIsStudentDeleting(false);
    }
  };

  const [isClassDeleting, setIsClassDeleting] = useState<boolean>(false);
  const [showClassDeleteError, setShowClassDeleteError] = useState<boolean>(false);
  const onClassDelete = async (reason?: string) => {
    if (!user || !classConfig?.id || isClassDeleting) {
      return;
    }

    setIsClassDeleting(true);

    const classId = classConfig?.id;
    let isDeleted: boolean | undefined = false;
    try {
      isDeleted = (await userService.deleteClass(user, classId, reason || ''))?.data.data;
    } catch (error) {
      console.log('deleteClass error', error);
    }

    if (!isDeleted) {
      setShowClassDeleteError(true);
      setIsClassDeleting(false);
      return;
    }

    try {
      dispatch(teacherDashboardActions.removeGroup(classId));
      dispatch(authActions.removeClass(classId));

      let selectClass: TeacherGroup | null = null;
      const classrooms = filterdClassrooms.filter(classroom => classroom.id !== classId);
      if (classrooms.length > 0) {
        selectClass = classrooms[0];
      }
      onSelectClass(selectClass);
    } catch (error) {
      console.log(error);
    } finally {
      setIsClassDeleting(false);
      setIsClassEdit(false);
    }
  };

  return {
    selectedFilter,
    selectedFilterText,
    allClassrooms,
    filterdClassrooms,
    classConfig,
    onCreateClass,
    createOrSaveClass,
    onFilter,
    onSelectClass,
    showClassCreatedModal,
    showExistingStudentsModal,
    onCloseClassCreatedModal,
    onCloseExistingStudentsModal,
    showCopyModal,
    setShowCopyModal,
    onCloseCopyModal,
    onCloseCancelModal,
    onShowCopyModal,
    showCancelModal,
    setShowCancelModal,
    onContinue,
    onShowCancelModal,
    user,
    loadingGroupDetails: groupDetails.isLoadingGroups,
    isSavingGroup: groupDetails.isSavingGroup,
    isCsvLoading,
    isCsvSending,
    setIsCsvLoading,
    csvFilesAdded,
    setCsvFilesAdded,
    showUploadCsvModal,
    addStudentsToNewGroup,
    showStudentsTableModal,
    onShowUploadCsvModal,
    onCloseUploadCsvModal,
    onShowStudentsTableModal,
    onBackStudentsTableModal,
    studentsData,
    setStudentsData,
    onCsvStudentsCreate,
    disableSubmitButton,
    setDisableSubmitButton,
    showNewStudentInput,
    setShowNewStudentInput,
    classStudentsExtraDataResponse,
    currentClassroom,
    isLoading,
    fetchDataError,
    refreshStudentsData,
    studentsProgress,
    statistics,
    selectedStudent,
    isClassEdit,
    setIsClassEdit,
    searchValue,
    setSearchValue,
    onShowStudentModal,
    onHideStudentModal,
    isStudentDataLoading,
    showStudentDataModal,
    selectedStudentData,
    showStudentEditModal,
    onShowStudentEditModal,
    onCloseStudentEditModal,
    editStudent,
    showUnitData,
    onShowUnitData,
    onHideUnitData,
    selectedUnit,
    showCsvStudentsCreateError,
    setShowCsvStudentsCreateError,
    onStudentDelete,
    isStudentDeleting,
    showStudentDeleteError,
    setShowStudentDeleteError,
    isClassDeleting,
    showClassDeleteError,
    setShowClassDeleteError,
    onClassDelete,
  };
};

export default UseMyClasses;
